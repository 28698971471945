import * as API from '@nauto/api';
import {
  findGroupById,
  hasNamedChildren,
} from 'components/groups/groups.redux';
import { Group, Groups } from 'components/groups/types';
import { validScoreSelector } from 'components/reports/reports.redux';
import {
  DurationType,
  PeriodAPIData,
  ReportRowData,
} from 'components/reports/types';
import { cleanPeriodData } from 'components/reports/utils';
import { TYPE } from 'constants/events';
import useVeraScoreToggle from 'hooks/use-vera-score-type-toggle';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Range } from 'utils/date-ranges';
import { languageIsJapanese } from 'utils/helpers';
import { ScoreVersion } from 'utils/vera-score-utils';
import { THIRTY_MINUTES } from '../constants';

interface State {
  groups: Groups;
}

interface InitialParams {
  fleetId: string;
  groupId: string;
  range: Range;
  duration: DurationType;
  isAggregateScore?: boolean;
}

interface ServerError {
  message: string;
  details: string;
}
interface UserReportGroupQueryPayload {
  data: API.reports.ReportItem[] | ServerError;
  validScoreGroupSelector: ReportRowData[];
  activeGroup: false | Group;
  canViewBySubfleet: boolean;
  isLoading: boolean;
  reportSelector: PeriodAPIData[];
  isError: boolean;
  fetchingError: ServerError;
}

interface UserReportGroupDataPayload {
  refetchReportGroupData: Dispatch<SetStateAction<InitialParams>>;
  reportGroupDataQuery: () => UserReportGroupQueryPayload;
}

const DURATIONS_MAP = {
  [DurationType.Daily]: 'daily_score',
  [DurationType.Weekly]: 'weekly_score',
  [DurationType.Monthly]: 'monthly_score',
};

const useReportGroupDataQuery = (
  initialParams: InitialParams,
): UserReportGroupDataPayload => {
  const [groupDataParams, setGroupDataParams] = useState(initialParams);
  const { activeGroupId, rootGroup } = useSelector(
    ({ groups: { activeGroupId, rootGroup } }: State) => ({
      activeGroupId,
      rootGroup,
    }),
  );
  const { isScoreVersionVera3 } = useVeraScoreToggle();

  const getGroupDataReport = async (): Promise<
    API.reports.ReportItem[] | ServerError
  > => {
    const version = isScoreVersionVera3 ? ScoreVersion.VERA3 : null;
    const {
      fleetId,
      groupId,
      range: { min, max },
      duration = DurationType.Weekly,
      isAggregateScore = false,
    } = groupDataParams;

    const { data } = await API.reports.getGroupReport({
      fleetId,
      groupId,
      range: { min: min.valueOf(), max: max.valueOf() },
      term: duration,
      version,
      isAggregateScore,
    });

    return data;
  };

  const getChildGroups = (groupsData, { groups: activeGroups = [] }: Group) => {
    const { duration } = groupDataParams;
    const collisionEventType = languageIsJapanese()
      ? TYPE.CONFIRMED_COLLISION_VERA2
      : TYPE.CONFIRMED_COLLISION_VERA3;
    const speedingType = isScoreVersionVera3 ? 'posting-speed' : 'speeding';
    const key = DURATIONS_MAP[duration];
    if (!activeGroups) {
      return [];
    }

    return activeGroups?.map(group => {
      const reportData = groupsData?.find(d => d.group_id === group.id);

      return {
        ...reportData,
        id: group.id,
        group,
        [key]:
          reportData &&
          reportData[key] &&
          reportData[key].map(data => ({
            ...data,
            ...cleanPeriodData(data, collisionEventType, speedingType),
          })),
      };
    });
  };

  const getReportSelector = (
    group: API.reports.ReportItem[],
  ): PeriodAPIData[] => {
    const { groupId, duration } = groupDataParams;
    const collisionEventType = languageIsJapanese()
      ? TYPE.CONFIRMED_COLLISION_VERA2
      : TYPE.CONFIRMED_COLLISION_VERA3;
    const speedingType = isScoreVersionVera3 ? 'posting-speed' : 'speeding';

    const activeGroup = group?.find(row => row.group_id === groupId);

    return activeGroup[DURATIONS_MAP[duration]].map(data => {
      return {
        ...data,
        ...cleanPeriodData(data, collisionEventType, speedingType),
      };
    });
  };

  const getActiveGroup = (): false | Group => {
    if (!activeGroupId || !rootGroup) {
      return false;
    }

    return findGroupById(rootGroup, activeGroupId);
  };

  const useGroupDataReport = (): UserReportGroupQueryPayload => {
    const { data, isLoading, isError } = useQuery(
      ['getGroupReportData', { ...groupDataParams, isScoreVersionVera3 }],
      getGroupDataReport,
      {
        cacheTime: THIRTY_MINUTES,
        staleTime: Infinity,
      },
    );

    const errorData = data as ServerError;
    if (errorData?.details && errorData?.message)
      return {
        data,
        validScoreGroupSelector: null,
        activeGroup: null,
        canViewBySubfleet: null,
        isLoading,
        reportSelector: null,
        isError,
        fetchingError: errorData,
      };

    const activeGroup = !isLoading && getActiveGroup();

    const reportSelector =
      !isLoading && getReportSelector(data as API.reports.ReportItem[]);

    const validScoreGroupSelector =
      activeGroup && validScoreSelector(getChildGroups(data, activeGroup));
    const canViewBySubfleet = activeGroup && hasNamedChildren(activeGroup);

    return {
      data,
      validScoreGroupSelector,
      activeGroup,
      canViewBySubfleet,
      isLoading,
      reportSelector,
      isError,
      fetchingError: null,
    };
  };

  useEffect(() => {
    if (!isEqual(initialParams, groupDataParams)) {
      setGroupDataParams(initialParams);
    }
  }, [initialParams]);

  return {
    refetchReportGroupData: setGroupDataParams,
    reportGroupDataQuery: useGroupDataReport,
  };
};

export default useReportGroupDataQuery;
