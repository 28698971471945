export enum UnifiedCoachingLabels {
  AggressiveDriving = 'unified-coachable-aggressive-driving',
  RiskyManeuver = 'unified-coachable-risky-maneuver',
  TrafficViolation = 'unified-coachable-traffic-violation',
  Distraction = 'unified-coachable-distraction',
  Drowsiness = 'unified-coachable-drowsiness',
  Collision = 'unified-coachable-collision',
  Tailgating = 'unified-coachable-tailgating',
  DeviceTampering = 'unified-coachable-device-tampering',
  RoadAwareness = 'unified-coachable-road-awareness',
  NearCollision = 'unified-coachable-near-collision',
  NegativeDriving = 'unified-coachable-negative-driving',
  Smoking = 'unified-coachable-smoking',
  Seatbelt = 'unified-coachable-seatbelt',
  Cellphone = 'unified-coachable-cellphone',
  Speeding = 'unified-coachable-speeding',
  ForwardCollisionWarning = 'unified-coachable-fcw',
  BackingUp = 'unified-coachable-backup',
  PedestrianCollisionWarning = 'unified-coachable-pcw',
  Cornering = 'unified-coachable-cornering',
  HardAcceleration = 'unified-coachable-hard-acceleration',
  HardBraking = 'unified-coachable-hard-braking',
  HitAndRun = 'unified-coachable-hit-and-run',
  RoadRage = 'unified-coachable-road-rage',
  StopSign = 'unified-coachable-traffic-violation-stop-sign',
  RollingStopSign = 'unified-coachable-traffic-violation-rolling-stop',
  IntersectionViolationWarning = 'unified-coachable-ivw',
  SafetyIncident = 'unified-coachable-safety-incident',
}

export enum FilterLocations {
  CoachingOverview = 'coaching-overview',
  DriverCoaching = 'driver-coaching',
  RecentSessions = 'recent-sessions',
}

export const COACHING_FILTERS = [
  UnifiedCoachingLabels.DeviceTampering,
  UnifiedCoachingLabels.HardAcceleration,
  UnifiedCoachingLabels.Collision,
  UnifiedCoachingLabels.NearCollision,
  UnifiedCoachingLabels.HardBraking,
  UnifiedCoachingLabels.Distraction,
  UnifiedCoachingLabels.Drowsiness,
  UnifiedCoachingLabels.RiskyManeuver,
  UnifiedCoachingLabels.RoadAwareness,
  UnifiedCoachingLabels.Tailgating,
  UnifiedCoachingLabels.BackingUp,
  UnifiedCoachingLabels.Cornering,
  UnifiedCoachingLabels.ForwardCollisionWarning,
  UnifiedCoachingLabels.HitAndRun,
  UnifiedCoachingLabels.RoadRage,
  UnifiedCoachingLabels.StopSign,
  UnifiedCoachingLabels.RollingStopSign,
  UnifiedCoachingLabels.IntersectionViolationWarning,
  UnifiedCoachingLabels.PedestrianCollisionWarning,
];

export const POLICY_VIOLATION_FILTERS = [
  UnifiedCoachingLabels.Speeding,
  UnifiedCoachingLabels.Seatbelt,
  UnifiedCoachingLabels.Smoking,
  UnifiedCoachingLabels.Cellphone,
];

export const FEATURE_FLAGGED_FILTERS = {
  [UnifiedCoachingLabels.ForwardCollisionWarning]: 'forwardCollisionWarning',
  [UnifiedCoachingLabels.Drowsiness]: 'drowsiness',
  [UnifiedCoachingLabels.RoadAwareness]: 'roadAwareness',
  [UnifiedCoachingLabels.RiskyManeuver]: 'riskyManeuver',
  [UnifiedCoachingLabels.BackingUp]: 'backingUpViolation',
  [UnifiedCoachingLabels.Cornering]: 'cornering',
  [UnifiedCoachingLabels.HardAcceleration]: 'hardAcceleration',
  [UnifiedCoachingLabels.DeviceTampering]: 'deviceTampering',
  [UnifiedCoachingLabels.RoadRage]: 'roadRage',
  [UnifiedCoachingLabels.HitAndRun]: 'hitAndRun',
  [UnifiedCoachingLabels.StopSign]: 'postFactoStopSign',
  [UnifiedCoachingLabels.RollingStopSign]: 'postFactoStopSign',
  [UnifiedCoachingLabels.IntersectionViolationWarning]:
    'intersectionViolationWarning',
  [UnifiedCoachingLabels.PedestrianCollisionWarning]:
    'pedestrianCollisionWarning',
  [UnifiedCoachingLabels.NearCollision]: 'nearCollisionEvents',
  [UnifiedCoachingLabels.HardBraking]: 'coachingBrakingFilter',
};

export const COACHING_FOCUS_AREA_DISPLAY_COUNT = 3;

// number of seconds before the session is posted to the backend
export const POSTING_TIMER_VALUE = 5;

export const COACHING_SESSION_TIMER = 5000;

export const FocusAreaRankings = {
  [UnifiedCoachingLabels.Collision]: 1,
  [UnifiedCoachingLabels.NearCollision]: 2,
  [UnifiedCoachingLabels.Drowsiness]: 3,
  [UnifiedCoachingLabels.Distraction]: 4,
  [UnifiedCoachingLabels.RiskyManeuver]: 5,
  [UnifiedCoachingLabels.AggressiveDriving]: 6,
  [UnifiedCoachingLabels.Tailgating]: 7,
};
