import { fetchRequest } from 'utils/requests';
import config from 'utils/config';
import { path } from 'utils/helpers';
import { ProfileState } from './profile.types';
import { fleetFeaturesSelector } from 'components/settings/settings.redux';
import { createStructuredSelector } from 'reselect';
import * as API from '@nauto/api';
import { currentFleetSelector } from 'components/auth/auth.reducer';
import { mapAlertResponseToNotifications } from 'components/severe-event-notifications/utils';
import { AlertType } from 'constants/alert-types';
import SevereAlertStore, {
  setAlertList,
} from 'components/severe-event-notifications/redux';

enum Constants {
  FETCH_NOTIFICATION_PREFS_START = 'profile/fetch-notification-prefs-start',
  FETCH_NOTIFICATION_PREFS_ERROR = 'profile/fetch-notification-prefs-error',
  FETCH_NOTIFICATION_PREFS_SUCCESS = 'profile/fetch-notification-prefs-success',
  UPDATE_NOTIFICATION_PREFS_START = 'profile/update-notification-prefs-start',
  UPDATE_NOTIFICATION_PREFS_ERROR = 'profile/update-notification-prefs-error',
  UPDATE_NOTIFICATION_PREFS_SUCCESS = 'profile/update-notification-prefs-success',
  FETCH_SMS_TOKEN_START = 'profile/fetch-sms-token-start',
  FETCH_SMS_TOKEN_ERROR = 'profile/fetch-sms-token-error',
  FETCH_SMS_TOKEN_SUCCESS = 'profile/fetch-sms-token-success',
  VERIFY_SMS_TOKEN_START = 'profile/verify-sms-token-start',
  VERIFY_SMS_TOKEN_ERROR = 'profile/verify-sms-token-error',
  VERIFY_SMS_TOKEN_SUCCESS = 'profile/verify-sms-token-success',
  FETCH_EMAIL_TOKEN_START = 'profile/fetch-email-start',
  FETCH_EMAIL_TOKEN_ERROR = 'profile/fetch-email-error',
  FETCH_EMAIL_TOKEN_SUCCESS = 'profile/fetch-email-success',
  VERIFY_EMAIL_TOKEN_START = 'profile/verify-email-start',
  VERIFY_EMAIL_TOKEN_ERROR = 'profile/verify-email-error',
  VERIFY_EMAIL_TOKEN_SUCCESS = 'profile/verify-email-success',
  CLEAR_ERROR = 'profile/clear-error',
}

export const initialState: ProfileState = {
  fetchingPrefs: false,
  updatingPrefs: false,
  prefs: {},
  error: '',
  modalError: '',
  fetchingInModal: false,
};

const DEFAULT_DEVICE_OFF_NOTIFICATION_THRESHOLD = 10;

export const selector = createStructuredSelector({
  currentFleet: currentFleetSelector,
  user: ({ user }) => user,
  profile: ({ profile }) => profile,
  features: fleetFeaturesSelector,
  fleetNotificationsThreshold: ({ fleetSettings: { fleet } }) =>
    (fleet && fleet.device_off_notification_threshold) ||
    DEFAULT_DEVICE_OFF_NOTIFICATION_THRESHOLD,
});
/* tslint:disable:no-duplicate-string */
/**
 * Fetch user notification preferences
 *
 * @param {string} id
 */
export const fetchPreferences = (
  userId: string,
  fleetId: string,
  customUserManagement,
) => (dispatch: (any) => void): Promise<void> => {
  dispatch({ type: Constants.FETCH_NOTIFICATION_PREFS_START });
  return API.profile
    .fetchPreferences({ userId, fleetId }, { url: config.admin_api_url_v3 })
    .then(({ alerts }) => {
      if (customUserManagement) {
        const severeAlerts = mapAlertResponseToNotifications(
          { alerts: Object.values(alerts)?.[0] ?? {} },
          [AlertType.Braking, AlertType.Distraction, AlertType.Tailgating],
        );

        SevereAlertStore.dispatch(setAlertList(severeAlerts));
      }

      return dispatch({
        type: Constants.FETCH_NOTIFICATION_PREFS_SUCCESS,
        payload: {
          prefs: alerts,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: Constants.FETCH_NOTIFICATION_PREFS_ERROR,
        payload: {
          error: error.message,
        },
      });
      throw error;
    });
};

/**
 * Update the notification preferences for a user
 *
 * @param {string} id
 * @param {*} alerts
 */
export const updatePreferences = (
  userId: string,
  fleetId: string,
  alert: any,
  roleId?: string,
) => (dispatch: (any) => void): Promise<void> => {
  dispatch({ type: Constants.UPDATE_NOTIFICATION_PREFS_START });
  return API.profile
    .updatePreferences(
      { userId, fleetId, roleId, body: alert },
      { url: config.admin_api_url_v3 },
    )
    .then(({ alerts }) => {
      return dispatch({
        type: Constants.UPDATE_NOTIFICATION_PREFS_SUCCESS,
        payload: {
          prefs: alerts,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: Constants.UPDATE_NOTIFICATION_PREFS_ERROR,
        payload: {
          error: error.message,
        },
      });
      throw error;
    });
};

/**
 * Send an SMS verification token to the user's phone
 *
 * @param {string} id
 * @param {string} phone
 */
export const sendSMSVerification = (id: string, phone: string) => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: Constants.FETCH_SMS_TOKEN_START });

  return API.profile
    .sendSMSVerification({ userId: id, phone }, { url: config.admin_api_url })
    .then(data => {
      return dispatch({
        type: Constants.FETCH_SMS_TOKEN_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: Constants.FETCH_SMS_TOKEN_ERROR,
        payload: {
          error: error.message,
        },
      });
      throw error;
    });
};

/**
 * Verify the SMS token sent to the user's phone
 *
 * @param {string} id
 * @param {string} token
 */
export const verifySMSToken = (id: string, token: string) => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: Constants.VERIFY_SMS_TOKEN_START });

  return API.profile
    .verifySMSToken({ userId: id, token }, { url: config.admin_api_url })
    .then(data => {
      return dispatch({
        type: Constants.VERIFY_SMS_TOKEN_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: Constants.VERIFY_SMS_TOKEN_ERROR,
        payload: {
          error: error.message,
        },
      });
      throw error;
    });
};

/**
 * Send an email verification token to the user's email address
 *
 * @param {string} id
 * @param {string} email
 * @param {string} password
 */
export const sendEmailVerification = ({
  id,
  email,
  password,
}: {
  id: string;
  email: string;
  password?: string;
}) => (dispatch: (any) => void): Promise<void> => {
  dispatch({ type: Constants.FETCH_EMAIL_TOKEN_START });

  return API.profile
    .sendEmailVerification(
      { userId: id, email, password },
      { url: config.admin_api_url },
    )
    .then(data => {
      return dispatch({
        type: Constants.FETCH_EMAIL_TOKEN_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: Constants.FETCH_EMAIL_TOKEN_ERROR,
        payload: {
          error: error.message,
        },
      });
      throw error;
    });
};

/**
 * Verify the token sent to the user's email
 *
 * @param {string} token
 */
export const verifyEmailToken = (id: string, email: string, token: string) => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: Constants.VERIFY_EMAIL_TOKEN_START });

  return API.profile
    .verifyEmailToken(
      { userId: id, email, token },
      { url: config.admin_api_url },
    )
    .then(({ data }) => {
      return dispatch({
        type: Constants.VERIFY_EMAIL_TOKEN_SUCCESS,
        payload: {
          data: {
            email: data.email,
            email_verified: data.email_verified,
          },
        },
      });
    })
    .catch(error => {
      return dispatch({
        type: Constants.VERIFY_EMAIL_TOKEN_ERROR,
        payload: {
          error: error.message,
        },
      });
    });
};
/* tslint:enable:no-duplicate-string */
export const clearError = () => ({
  type: Constants.CLEAR_ERROR,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case Constants.FETCH_NOTIFICATION_PREFS_START:
      return {
        ...state,
        fetchingPrefs: true,
      };

    case Constants.FETCH_NOTIFICATION_PREFS_ERROR:
      return {
        ...state,
        fetchingPrefs: false,
        prefs: {},
        error: action.payload.error,
      };

    case Constants.FETCH_NOTIFICATION_PREFS_SUCCESS:
      return {
        ...state,
        fetchingPrefs: false,
        error: '',
        prefs: action.payload.prefs,
      };

    case Constants.UPDATE_NOTIFICATION_PREFS_START:
      return {
        ...state,
        updatingPrefs: true,
      };

    case Constants.UPDATE_NOTIFICATION_PREFS_ERROR:
      return {
        ...state,
        updatingPrefs: false,
        error: action.payload.error,
      };

    case Constants.UPDATE_NOTIFICATION_PREFS_SUCCESS:
      return {
        ...state,
        updatingPrefs: false,
        error: '',
        prefs: action.payload.prefs,
      };

    case Constants.CLEAR_ERROR:
      return {
        ...state,
        modalError: '',
      };

    case Constants.VERIFY_EMAIL_TOKEN_START:
    case Constants.FETCH_EMAIL_TOKEN_START:
    case Constants.VERIFY_SMS_TOKEN_START:
    case Constants.FETCH_SMS_TOKEN_START:
      return {
        ...state,
        fetchingInModal: true,
        modalError: '',
      };

    case Constants.VERIFY_EMAIL_TOKEN_ERROR:
    case Constants.FETCH_EMAIL_TOKEN_ERROR:
    case Constants.VERIFY_SMS_TOKEN_ERROR:
    case Constants.FETCH_SMS_TOKEN_ERROR:
      return {
        ...state,
        fetchingInModal: false,
        modalError: action.payload.error,
      };

    case Constants.VERIFY_EMAIL_TOKEN_SUCCESS:
    case Constants.FETCH_EMAIL_TOKEN_SUCCESS:
    case Constants.VERIFY_SMS_TOKEN_SUCCESS:
    case Constants.FETCH_SMS_TOKEN_SUCCESS:
      return {
        ...state,
        fetchingInModal: false,
        modalError: '',
      };

    default:
      return state;
  }
};
