import * as API from '@nauto/api';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { TEN_MINUTES, THIRTY_MINUTES } from '../constants';
import useFeatureFlags from 'hooks/use-feature-flags';

interface Params {
  fleetId: string;
  group: string;
  driverId: string;
  options: Partial<API.Options>;
}

type UseCoachingSessionProperties = {
  useCoachingSession(): UseQueryResult;
};

/** this query hook is used in non-driver-profile flows like
 * coaching overview page. So, not including the driver webapp
 * coaching session API.
 **/
const useCoachingSessionQuery = (
  initialParams: Params,
): UseCoachingSessionProperties => {
  const [fetchParams, setFetchParams] = useState<Params>(initialParams);
  const { offlineCoaching } = useFeatureFlags();

  const fetchCoachingSession = async () => {
    const { fleetId, group, driverId, options } = fetchParams;
    const { data } = (await API.coaching.getCoachingSessions(
      {
        fleetId,
        group,
        driverId,
        coachingOffline: offlineCoaching,
      },
      options,
    )) as Record<string, unknown>;

    return data;
  };

  const useCoachingSession = () =>
    useQuery(['fetchCoachingSession', fetchParams], fetchCoachingSession, {
      refetchInterval: TEN_MINUTES,
      staleTime: Infinity,
      cacheTime: offlineCoaching ? 0 : THIRTY_MINUTES,
    });

  useEffect(() => {
    if (!isEqual(initialParams, fetchParams)) {
      setFetchParams(initialParams);
    }
  }, [initialParams, fetchParams]);

  return { useCoachingSession };
};

export default useCoachingSessionQuery;
