import moment from 'moment';
import { strings } from './i18n-coaching-strings';
import {
  UnifiedCoachingLabels,
  COACHING_FOCUS_AREA_DISPLAY_COUNT,
  FilterLocations,
  COACHING_FILTERS,
  FocusAreaRankings,
  POLICY_VIOLATION_FILTERS,
  FEATURE_FLAGGED_FILTERS,
} from 'constants/coaching';
import { CoachingData } from './events-coached-bar';
import { percentage } from 'utils/math';
import { MixpanelEventKeys } from 'components/mixpanel/tags';
import { colors } from '@nauto/core';
import { getVeraScore, formattedScore } from 'utils/report-utils';
import { TFunction } from 'i18next';
import { ReportRowData } from 'components/reports/types';
import { policyViolationsWhiteList } from 'components/events/utils';
import { FILTER_DROPDOWN } from 'constants/events';
import { Session } from 'components/driver/coaching/session-note';
import { TableViews } from './types';
import { compact } from 'lodash-es';
import { Flags } from 'components/feature-flags/flags';
import { EventFilters } from './all-events-filter';

export const coachingDateRange = () => ({
  start: moment()
    .subtract('30', 'd')
    .startOf('day')
    .toISOString(),
  end: moment().toISOString(),
});

export const formatCoachingArea = ({
  t,
  row,
  appliedFilters,
  vera3 = false,
}: {
  t: TFunction;
  row: ReportRowData;
  appliedFilters: UnifiedCoachingLabels[];
  vera3?: boolean;
}) => {
  const { coachingData } = row;
  if (coachingData?.uncoached_live_count > 0) {
    const focusAreaKeys = Object.values(UnifiedCoachingLabels);
    const focusArea = {};
    focusAreaKeys.forEach(label => {
      const labelCount = coachingData.uncoached_live[label];
      if (labelCount > 0) {
        focusArea[label] = labelCount;
      }
    });
    const sortedFocusAreas = sortFocusAreas(Object.entries(focusArea));
    const labelledFocusedAreas = sortedFocusAreas
      .filter(area => appliedFilters.includes(area[0]))
      .splice(0, COACHING_FOCUS_AREA_DISPLAY_COUNT);
    return vera3
      ? labelledFocusedAreas
      : labelledFocusedAreas
          .map(
            sortedFocusArea =>
              `${strings(t, sortedFocusArea[0])} (${sortedFocusArea[1]})`,
          )
          .join(', ');
  }
  return vera3 ? [] : '';
};

export const hasCoachableEvents = item =>
  item.coachingData?.uncoached_live_count > 0;

const getOptionsMapper = ({ t, excludeFilters }) => ({ value }) => ({
  label: strings(t, value),
  value: value,
  hide: excludeFilters.includes(value),
  showAsterisk: policyViolationsWhiteList.includes(value),
});
export const coachingFilterOptions = ({ excludeFilters, t }) => {
  const shouldShowParent = option => !option.hide;
  const optionMapper = getOptionsMapper({ t, excludeFilters });

  const highRiskOptions = [
    { value: UnifiedCoachingLabels.Collision },
    { value: UnifiedCoachingLabels.ForwardCollisionWarning },
    { value: UnifiedCoachingLabels.NearCollision },
    { value: UnifiedCoachingLabels.IntersectionViolationWarning },
    { value: UnifiedCoachingLabels.PedestrianCollisionWarning },
    // add pedestrian collision warning
  ].map(optionMapper);
  const inattentionOptions = [
    { value: UnifiedCoachingLabels.Cellphone },
    { value: UnifiedCoachingLabels.Distraction },
    { value: UnifiedCoachingLabels.Drowsiness },
    { value: UnifiedCoachingLabels.RoadAwareness },
    { value: UnifiedCoachingLabels.Smoking },
  ].map(optionMapper);
  const trafficViolationOptions = [
    { value: UnifiedCoachingLabels.Speeding },
    { value: UnifiedCoachingLabels.Seatbelt },
    { value: UnifiedCoachingLabels.StopSign },
    { value: UnifiedCoachingLabels.RollingStopSign },
    // add illegal turn
    // add intersection +  sub options
    // add one way street
    // add wrong way
  ].map(optionMapper);
  const aggressiveDrivingOptions = [
    { value: UnifiedCoachingLabels.Cornering },
    { value: UnifiedCoachingLabels.RiskyManeuver },
    { value: UnifiedCoachingLabels.HardAcceleration },
    { value: UnifiedCoachingLabels.HardBraking },
    { value: UnifiedCoachingLabels.Tailgating },
    { value: UnifiedCoachingLabels.RoadRage },
    { value: UnifiedCoachingLabels.HitAndRun },
  ].map(optionMapper);
  const nonComplianceOptions = [
    { value: UnifiedCoachingLabels.BackingUp },
    { value: UnifiedCoachingLabels.DeviceTampering },
  ].map(optionMapper);

  return [
    {
      label: t('High risk'),
      title: FILTER_DROPDOWN.HIGH_RISK,
      options: highRiskOptions,
    },
    {
      label: t('Inattention'),
      title: FILTER_DROPDOWN.INATTENTION,
      options: inattentionOptions,
    },
    {
      label: t('Traffic violation'),
      title: FILTER_DROPDOWN.TRAFFIC_VIOLATION,
      hide: !trafficViolationOptions.some(shouldShowParent),
      options: trafficViolationOptions,
    },
    {
      label: t('Aggressive driving'),
      title: FILTER_DROPDOWN.AGGRESSIVE_DRIVING,
      options: aggressiveDrivingOptions,
    },
    {
      label: t('Non-compliance'),
      title: FILTER_DROPDOWN.NON_COMPLIANCE,
      hide: !nonComplianceOptions.some(shouldShowParent),
      options: nonComplianceOptions,
    },
  ];
};

export const mapCoachingFilterOptions = ({ defaultLabels, t }) => {
  const optionMapper = getOptionsMapper({ t, excludeFilters: [] });
  const highRiskOptions = [
    { value: UnifiedCoachingLabels.Collision },
    { value: UnifiedCoachingLabels.ForwardCollisionWarning },
    { value: UnifiedCoachingLabels.NearCollision },
    { value: UnifiedCoachingLabels.IntersectionViolationWarning },
    { value: UnifiedCoachingLabels.PedestrianCollisionWarning },
    // add pedestrian collision warning
  ]
    .filter(label => defaultLabels.includes(label.value))
    .map(optionMapper);
  const inattentionOptions = [
    { value: UnifiedCoachingLabels.Cellphone },
    { value: UnifiedCoachingLabels.Distraction },
    { value: UnifiedCoachingLabels.Drowsiness },
    { value: UnifiedCoachingLabels.RoadAwareness },
    { value: UnifiedCoachingLabels.Smoking },
  ]
    .filter(label => defaultLabels.includes(label.value))
    .map(optionMapper);
  const trafficViolationOptions = [
    { value: UnifiedCoachingLabels.Speeding },
    { value: UnifiedCoachingLabels.Seatbelt },
    { value: UnifiedCoachingLabels.StopSign },
    { value: UnifiedCoachingLabels.RollingStopSign },
    // add illegal turn
    // add intersection +  sub options
    // add one way street
    // add wrong way
  ]
    .filter(label => defaultLabels.includes(label.value))
    .map(optionMapper);
  const aggressiveDrivingOptions = [
    { value: UnifiedCoachingLabels.Cornering },
    { value: UnifiedCoachingLabels.RiskyManeuver },
    { value: UnifiedCoachingLabels.HardAcceleration },
    { value: UnifiedCoachingLabels.HardBraking },
    { value: UnifiedCoachingLabels.Tailgating },
    { value: UnifiedCoachingLabels.RoadRage },
    { value: UnifiedCoachingLabels.HitAndRun },
  ]
    .filter(label => defaultLabels.includes(label.value))
    .map(optionMapper);
  const nonComplianceOptions = [
    { value: UnifiedCoachingLabels.BackingUp },
    { value: UnifiedCoachingLabels.DeviceTampering },
  ]
    .filter(label => defaultLabels.includes(label.value))
    .map(optionMapper);

  return compact([
    highRiskOptions?.length && {
      label: t('High risk'),
      title: FILTER_DROPDOWN.HIGH_RISK,
      options: highRiskOptions,
    },
    inattentionOptions?.length && {
      label: t('Inattention'),
      title: FILTER_DROPDOWN.INATTENTION,
      options: inattentionOptions,
    },
    trafficViolationOptions?.length > 0 && {
      label: t('Traffic violation'),
      title: FILTER_DROPDOWN.TRAFFIC_VIOLATION,
      options: trafficViolationOptions,
    },
    aggressiveDrivingOptions?.length && {
      label: t('Aggressive driving'),
      title: FILTER_DROPDOWN.AGGRESSIVE_DRIVING,
      options: aggressiveDrivingOptions,
    },
    nonComplianceOptions?.length && {
      label: t('Non-compliance'),
      title: FILTER_DROPDOWN.NON_COMPLIANCE,
      options: nonComplianceOptions,
    },
  ]);
};

export const buildCoachingRecipes = (filters: UnifiedCoachingLabels[]) => {
  const filterLabels = filters.map(recipe => ({
    name: recipe,
    value: 'true',
  }));
  const defaultLabel = [{ name: 'coaching-status', value: 'uncoached' }];
  return filters.length ? filterLabels : defaultLabel;
};

const highPriorityEvents = [
  UnifiedCoachingLabels.Collision,
  UnifiedCoachingLabels.NearCollision,
];

export const sortFocusAreasByLabel = (
  labels: UnifiedCoachingLabels[],
): UnifiedCoachingLabels[] => {
  return labels.sort((a, b) => {
    const aIsPriority = highPriorityEvents.includes(a);
    const bIsPriority = highPriorityEvents.includes(b);

    if (aIsPriority && bIsPriority) {
      return a === UnifiedCoachingLabels.Collision ? -1 : 1;
    } else if (aIsPriority) {
      return -1;
    } else if (bIsPriority) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const sortFocusAreas = (labels: any[]): any[] => {
  return labels.sort((a, b) => {
    const hasCount = Array.isArray(a);
    const firstEl = hasCount ? a[0] : a;
    const secondEl = hasCount ? b[0] : b;
    if (highPriorityEvents.includes(firstEl)) {
      return -1;
    } else if (highPriorityEvents.includes(secondEl)) {
      return 1;
    } else {
      return hasCount ? a[1] - b[1] : a - b;
    }
  });
};

export const splitDateIntoIntervals = (
  range: { start: string; end: string },
  numberOfIntervals = 4,
): Record<'start' | 'end', string>[] => {
  const startDate = moment(range.start);
  const endDate = moment(range.end);

  const duration = moment.duration(endDate.diff(startDate));
  const days = Math.round(duration.asDays());
  const daysPerRange = Math.floor(days / numberOfIntervals);

  let start: moment.Moment, end: moment.Moment;
  const ranges: Record<'start' | 'end', string>[] = [];

  for (let i = 1; i <= numberOfIntervals; i++) {
    if (i == 1) {
      start = startDate;
      end = moment(startDate).add(daysPerRange, 'days');
    } else if (i == numberOfIntervals) {
      start = moment(end).startOf('day');
      end = endDate;
    } else {
      start = moment(end).startOf('day');
      end = moment(start)
        .add(daysPerRange, 'days')
        .startOf('day');
    }
    ranges.push({ start: start.toISOString(), end: end.toISOString() });
  }

  return ranges;
};

export const calculateEventsCoached = (data: CoachingData): number => {
  const { coached_live_count = 0, uncoached_live_count = 0 } = data || {};
  const totalEvents = coached_live_count + uncoached_live_count;
  return percentage(coached_live_count, totalEvents);
};

export const readableCoachingLabelMap = {
  [UnifiedCoachingLabels.DeviceTampering]: 'device-tampering',
  [UnifiedCoachingLabels.Collision]: 'collision',
  [UnifiedCoachingLabels.NearCollision]: 'near-collision',
  [UnifiedCoachingLabels.Distraction]: 'distraction',
  [UnifiedCoachingLabels.Drowsiness]: 'drowsiness',
  [UnifiedCoachingLabels.RiskyManeuver]: 'risky-maneuver',
  [UnifiedCoachingLabels.RoadAwareness]: 'road-awareness',
  [UnifiedCoachingLabels.Tailgating]: 'tailgating',
  [UnifiedCoachingLabels.BackingUp]: 'backup',
  [UnifiedCoachingLabels.Cellphone]: 'cell-phone',
  [UnifiedCoachingLabels.Cornering]: 'cornering',
  [UnifiedCoachingLabels.ForwardCollisionWarning]: 'forward-collision-warning',
  [UnifiedCoachingLabels.HardAcceleration]: 'hard-acceleration',
  [UnifiedCoachingLabels.HardBraking]: 'hard-braking',
  [UnifiedCoachingLabels.Seatbelt]: 'seat-belt',
  [UnifiedCoachingLabels.Smoking]: 'smoking',
  [UnifiedCoachingLabels.Speeding]: 'speeding',
  [UnifiedCoachingLabels.HitAndRun]: 'hit-and-run',
  [UnifiedCoachingLabels.RoadRage]: 'road-rage',
  [UnifiedCoachingLabels.StopSign]: 'stop-sign',
  [UnifiedCoachingLabels.RollingStopSign]: 'rolling-stop',
  [UnifiedCoachingLabels.IntersectionViolationWarning]:
    'intersection-violation-warning',
  [UnifiedCoachingLabels.PedestrianCollisionWarning]:
    'pedestrian-collision-warning',
};

export const coachingLabelMap = {
  'device-tampering': UnifiedCoachingLabels.DeviceTampering,
  collision: UnifiedCoachingLabels.Collision,
  'near-collision': UnifiedCoachingLabels.NearCollision,
  distraction: UnifiedCoachingLabels.Distraction,
  drowsiness: UnifiedCoachingLabels.Drowsiness,
  'risky-maneuver': UnifiedCoachingLabels.RiskyManeuver,
  'road-awareness': UnifiedCoachingLabels.RoadAwareness,
  tailgating: UnifiedCoachingLabels.Tailgating,
  backup: UnifiedCoachingLabels.BackingUp,
  'cell-phone': UnifiedCoachingLabels.Cellphone,
  cornering: UnifiedCoachingLabels.Cornering,
  'forward-collision-warning': UnifiedCoachingLabels.ForwardCollisionWarning,
  'hard-acceleration': UnifiedCoachingLabels.HardAcceleration,
  'hard-braking': UnifiedCoachingLabels.HardBraking,
  'seat-belt': UnifiedCoachingLabels.Seatbelt,
  smoking: UnifiedCoachingLabels.Smoking,
  speeding: UnifiedCoachingLabels.Speeding,
  'hit-and-run': UnifiedCoachingLabels.HitAndRun,
  'road-rage': UnifiedCoachingLabels.RoadRage,
  'stop-sign': UnifiedCoachingLabels.StopSign,
  'rolling-stop': UnifiedCoachingLabels.RollingStopSign,
  'pedestrian-collision-warning':
    UnifiedCoachingLabels.PedestrianCollisionWarning,
  'intersection-violation-warning':
    UnifiedCoachingLabels.IntersectionViolationWarning,
};
export enum FilterActions {
  SelectAll = 'select-all',
  Selection = 'selection',
  Reset = 'reset',
  Confirm = 'confirm',
  Cancel = 'cancel',
}

export const mixpanelEventsMap = {
  [FilterLocations.CoachingOverview]: {
    [FilterActions.SelectAll]: MixpanelEventKeys.CoachingAllFocusAreaSelectAll,
    [FilterActions.Selection]: MixpanelEventKeys.CoachingAllFocusAreaSelection,
    [FilterActions.Reset]: MixpanelEventKeys.CoachingFocusAreaReset,
    [FilterActions.Cancel]: MixpanelEventKeys.CoachingAllFocusAreaCancel,
    [FilterActions.Confirm]: MixpanelEventKeys.CoachingAllFocusAreaConfirm,
  },
  [FilterLocations.RecentSessions]: {
    [FilterActions.SelectAll]: MixpanelEventKeys.CoachingAllFocusAreaSelectAll,
    [FilterActions.Selection]: MixpanelEventKeys.CoachingAllFocusAreaSelection,
    [FilterActions.Reset]: MixpanelEventKeys.CoachingFocusAreaReset,
    [FilterActions.Cancel]: MixpanelEventKeys.CoachingAllFocusAreaCancel,
    [FilterActions.Confirm]: MixpanelEventKeys.CoachingAllFocusAreaConfirm,
  },
  [FilterLocations.DriverCoaching]: {
    [FilterActions.SelectAll]:
      MixpanelEventKeys.CoachingDriverAllFocusAreaSelectAll,
    [FilterActions.Selection]:
      MixpanelEventKeys.CoachingDriverAllFocusAreaSelection,
    [FilterActions.Reset]: MixpanelEventKeys.CoachingDriverFocusAreaReset,
    [FilterActions.Cancel]: MixpanelEventKeys.CoachingDriverAllFocusAreaCancel,
    [FilterActions.Confirm]:
      MixpanelEventKeys.CoachingDriverAllFocusAreaConfirm,
  },
};

export const isAllFiltersSelected = (eventFilters: EventFilters): boolean =>
  eventFilters.applied.length === COACHING_FILTERS.length;

export const getFiltersAppliedLabel = (
  allFiltersSelected: boolean,
  eventFilters: EventFilters,
  t: TFunction,
  isMobileView = false,
): string => {
  const appliedFilters: UnifiedCoachingLabels[] = sortFocusAreasByLabel(
    eventFilters.applied,
  );
  if (allFiltersSelected) {
    return t('All focus areas');
  }

  if (isMobileView && appliedFilters.length > 2) {
    return `${strings(t, appliedFilters[0])}, ${strings(
      t,
      appliedFilters[1],
    )} +${appliedFilters.length - 2}`;
  }
  if (appliedFilters.length > 3) {
    return `${strings(t, appliedFilters[0])}, ${strings(
      t,
      appliedFilters[1],
    )}, ${strings(t, appliedFilters[2])} +${appliedFilters.length - 3}`;
  }

  if (!appliedFilters.length) {
    return t('No focus areas');
  }

  return appliedFilters.map(filter => strings(t, filter)).join(', ');
};

export const getFilterActiveColor = (allFiltersSelected: boolean) =>
  allFiltersSelected ? colors.black : colors.white;

export const generateRankData = (coachingData, driver) => {
  const { uncoached_live } = coachingData;
  const maxRank = Infinity;
  let rank = maxRank;
  const labelsWithCount = Object.keys(uncoached_live).filter(
    key => uncoached_live[key] > 0,
  );
  for (const key of labelsWithCount) {
    if (FocusAreaRankings[key]) {
      rank = Math.min(FocusAreaRankings[key] || maxRank, rank);
    }
  }
  const label = Object.keys(FocusAreaRankings)[rank - 1];
  const driverScore = formattedScore({
    score: getVeraScore(driver?.agg_score) || -1,
  });
  return { rank, count: uncoached_live[label], vera: driverScore };
};

export const sortByRank = (a, b, modifier) => {
  let order;
  if (a.ranking.rank === b.ranking.rank) {
    if (a.ranking.count === b.ranking.count) {
      if (a.ranking.vera < b.ranking.vera) {
        order = -1;
      } else if (a.ranking.vera > b.ranking.vera) {
        order = 1;
      } else {
        order = 0;
      }
    } else if (a.ranking.count > b.ranking.count) {
      order = -1;
    } else if (a.ranking.count < b.ranking.count) {
      order = 1;
    } else {
      order = 0;
    }
  } else if (a.ranking.rank < b.ranking.rank) {
    order = -1;
  } else if (a.ranking.rank > b.ranking.rank) {
    order = 1;
  } else {
    order = 0;
  }
  return modifier * order;
};

interface LabelsMap {
  heading: string;
  search: string;
  noEvents: string;
  fileName: string;
}

export const coachingLabelsMap = (
  t: TFunction,
): Record<TableViews, LabelsMap> => ({
  [TableViews.Drivers]: {
    heading: t('Drivers to coach'),
    search: t('Find a driver'),
    noEvents: t("We haven't identified any coachable event for your fleet"),
    fileName: `Nauto coaching history - ${moment().format('L')}`,
  },
  [TableViews.Subfleets]: {
    heading: t('Subfleets'),
    search: t('Find a subfleet'),
    noEvents: t(
      "We haven't identified any subfleets with coachable events for your fleet",
    ),
    fileName: `Nauto subfleets coaching history - ${moment().format('L')}`,
  },
  [TableViews.RecentSessions]: {
    heading: t('Recent sessions'),
    search: t('Find a recent session'),
    noEvents: t(
      "We haven't identified any recent coachable sessions for your fleet",
    ),
    fileName: `Nauto coaching sessions - ${moment().format('L')}`,
  },
});

export const filterByBackfillDate = (sessions: Session[]): Session[] =>
  sessions?.filter(session => {
    const timestamp = moment(session.timestamp);
    const hasNoFocusAreas = timestamp.isBefore('2020-08-31 07:05:30.529307');

    if (hasNoFocusAreas) {
      return false;
    }

    return true;
  });

export const getExcludedFilters = (
  flags: Flags,
  location: FilterLocations,
): string[] => {
  const additionalFiltersToExclude = Object.keys(
    FEATURE_FLAGGED_FILTERS,
  ).filter(feature => !flags[FEATURE_FLAGGED_FILTERS[feature]]);

  return [
    ...Object.values(additionalFiltersToExclude),
    ...(location !== FilterLocations.RecentSessions
      ? POLICY_VIOLATION_FILTERS
      : []),
    ...(flags.eventsDenyList || []),
  ];
};
