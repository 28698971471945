const path = '//nauto-public.s3.amazonaws.com/apps/assets/img';
export const img = {
  vera: `${path}/vera.svg`,
  crown_badge: `${path}/crown-badge.svg`,
  logo_horizontal_green: `${path}/logo-horizontal-green.svg`,
  nauto_3d: `${path}/nauto-3d.png`,
  driver_fallback: `${path}/driver-fallback.svg`,
  animated_logo: `${path}/loading.gif`,
  mobile_nav_logo: `${path}/02-Logo-Registered-Trademark.svg`,
};
