import { Thresholds } from 'components/severe-event-notifications/types';
import { AlertType } from 'constants/alert-types';
import kebabCase from 'lodash-es/kebabCase';
import mapKeys from 'lodash-es/mapKeys';
import { UnifiedCoachingLabels } from 'constants/coaching';

export interface Flags {
  isLDReady?: boolean;
  /* permanent region split */
  distractionThresholdRange: {
    min: number;
    max: number;
  };
  helpCenter: boolean;
  sfHelpcenter: boolean;
  sfHelpCenterPages: boolean;
  manualAddDriver: boolean;
  prevent: boolean;
  speedingStartDate: string;
  taggingHelpModal: boolean;
  trainingLink: boolean;
  tripReportsStartDate: string;
  vera2Cutover: string;
  veraReportsBanner: boolean;
  tagTrips: boolean;
  developerDocs: boolean;
  subfleetSafetyAssessment: boolean;
  sparklines: boolean;
  twelveMonthEventInsights: boolean;
  extendedParking: boolean;
  resizeHeader: boolean;
  /* product readiness blocked */
  coachingBrakingFilter: boolean;
  appcues: boolean;
  distractionSubtypes: boolean;
  installerQrCode: boolean;
  mapSidebarInfo: boolean;
  severeTailgatingAlerts: boolean;
  skippedFaces: boolean;
  tailgatingBetaBanner: boolean;
  useAddressFromTripApiResponse: boolean;
  deltaV: boolean;
  obstructionDetectionNotification: boolean;
  newOrganizationTables: boolean;
  superfleetEvents: boolean;
  driverFormSubfleetSelection: boolean;
  trajectoryReconstruction: false;
  /* Regional QA needed */
  coaching: boolean;
  rtaMarker: boolean;
  suspectedCollision: boolean;
  suspectedCollisionNotification: boolean;
  tagTripsFromMap: boolean;
  highlightUntaggedTrips: boolean;
  tripRates: boolean;
  newUserManagement: boolean;
  unassignedHours: boolean;
  driverIdDebugTool: boolean;
  downstreamMessagesMedia: boolean;
  speedingInVehicleAlertSettings: boolean;
  criticalEvents: boolean;
  seatbeltViolations: boolean;
  nearCollisionEvents: boolean;
  newEventFilter: boolean;
  eventsListInfiniteScroll: boolean;
  forwardCollisionWarning: boolean;
  newCoaching: boolean;
  collisionReportsColumn: boolean;
  drowsiness: boolean;
  postedSpeedLimit: boolean;
  falsePositiveLabel: boolean;
  canViewSubfleetToggle: boolean;
  silentDevicesReword: boolean;
  newEntityPicker: boolean;
  importDriversCsv: boolean;
  newVehiclesMenu: boolean;
  subfleetDepth: number;
  noVideoDownload: boolean;
  newNavigationUi: boolean;
  coachingSafetyPerformance: boolean;
  backingUpViolation: boolean;
  customEventSeverity: 1 | 2 | 3;
  fnolDateAndTime: boolean;
  eventFilterSelections: string[];
  newEventsIcons: boolean;
  vera3: boolean;
  reportsGridBeta: boolean;
  customUserManagement: boolean;
  newDeviceHealthUi: boolean;
  pedestrianCollisionWarning: boolean;
  focusAreaRanking: boolean;
  eventSourcedFnol: boolean;
  unifiedLabelRecipe: boolean;
  fleetSelectorSearch: boolean;
  fleetPageUi: boolean;
  unifiedTailgatingLabel: boolean;
  stitchedVideoPlayback: boolean;
  noOutwardVideo: boolean;
  eventDetailsUi: boolean;
  riskyManeuver: boolean;
  roadAwareness: boolean;
  newReporting: boolean;
  eventsMapsRouteConfig: boolean;
  cornering: boolean;
  hardAcceleration: boolean;
  deviceTampering: boolean;
  coachingEventDetailsUi: boolean;
  installerCsvUpload: boolean;
  showSpeedInfo: boolean;
  eventsSkiptripParam: boolean;
  eventsLimitParam: number;
  postFactoStopSign: boolean;
  hitAndRun: boolean;
  roadRage: boolean;
  comboEvents: boolean;
  intersectionViolationWarning: boolean;
  newHome: boolean;
  customVideoRequest2: boolean;
  zendeskNewUrl: boolean;
  offlineCoaching: boolean;
  provisionerUserColumns: boolean;
  riskBasedTailgating: boolean;
  riskBasedDistraction: boolean;
  riskBasedViolations: boolean;
  riskBasedAbc: boolean;
  groupHierarchy: boolean;
  tailgatingOverlay: boolean;
  insightsManagerPerformance: boolean;
  driverRanking: boolean;
  repeatedEventsVideoRequest: boolean;
  sidebarFleetEntityCount: boolean;
  videoDownload: boolean;
  reportTemplates: boolean;
  pgE: boolean;
  newInsights2: boolean;
  insightsDriverSafety: boolean;
  defaultEventSeverity: 1 | 2 | 3;
  eventsDenyList: string[];
  safetyIncidentEvent: boolean;
  cvrTimeRange: boolean;
  collisionReportMinDate: boolean;
  vehiclesModule: boolean;
  insightsCpmm: boolean;
}

export const defaultFlags: Flags = {
  // permanent region split
  distractionThresholdRange: {
    min: Thresholds[AlertType.Distraction].Lower,
    max: Thresholds[AlertType.Distraction].Upper,
  },
  helpCenter: false,
  sfHelpcenter: false,
  sfHelpCenterPages: false,
  manualAddDriver: false,
  prevent: false,
  speedingStartDate: 'Apr 1 2019 GMT-0000',
  taggingHelpModal: false,
  trainingLink: false,
  tripReportsStartDate: 'Jan 1 2018 GMT-0800',
  vera2Cutover: 'Mar 16 2019 GMT-0000',
  veraReportsBanner: false,
  developerDocs: false,
  subfleetSafetyAssessment: false,
  sparklines: false,
  twelveMonthEventInsights: false,
  extendedParking: false,
  resizeHeader: false,
  /* product readiness blocked */
  coachingBrakingFilter: false,
  appcues: false,
  distractionSubtypes: false,
  installerQrCode: false,
  mapSidebarInfo: false,
  severeTailgatingAlerts: false,
  skippedFaces: false,
  tailgatingBetaBanner: false,
  tagTrips: false,
  useAddressFromTripApiResponse: false,
  deltaV: false,
  obstructionDetectionNotification: false,
  newOrganizationTables: false,
  superfleetEvents: false,
  driverFormSubfleetSelection: false,
  trajectoryReconstruction: false,
  /* Regional QA needed */
  coaching: false,
  rtaMarker: false,
  suspectedCollision: false,
  suspectedCollisionNotification: false,
  highlightUntaggedTrips: false,
  tagTripsFromMap: false,
  tripRates: false,
  newUserManagement: false,
  unassignedHours: false,
  driverIdDebugTool: false,
  downstreamMessagesMedia: false,
  speedingInVehicleAlertSettings: false,
  criticalEvents: false,
  seatbeltViolations: false,
  nearCollisionEvents: false,
  newEventFilter: false,
  eventsListInfiniteScroll: false,
  forwardCollisionWarning: false,
  newCoaching: false,
  collisionReportsColumn: false,
  drowsiness: false,
  postedSpeedLimit: false,
  falsePositiveLabel: false,
  canViewSubfleetToggle: false,
  silentDevicesReword: false,
  newEntityPicker: false,
  importDriversCsv: false,
  newVehiclesMenu: false,
  subfleetDepth: 4,
  noVideoDownload: false,
  newNavigationUi: false,
  coachingSafetyPerformance: false,
  backingUpViolation: false,
  customEventSeverity: 1,
  fnolDateAndTime: false,
  eventFilterSelections: [],
  newEventsIcons: false,
  vera3: false,
  reportsGridBeta: false,
  customUserManagement: false,
  newDeviceHealthUi: false,
  pedestrianCollisionWarning: false,
  focusAreaRanking: false,
  eventSourcedFnol: false,
  unifiedLabelRecipe: false,
  fleetSelectorSearch: false,
  fleetPageUi: false,
  unifiedTailgatingLabel: false,
  stitchedVideoPlayback: false,
  noOutwardVideo: false,
  eventDetailsUi: false,
  riskyManeuver: false,
  roadAwareness: false,
  newReporting: false,
  eventsMapsRouteConfig: false,
  cornering: false,
  hardAcceleration: false,
  deviceTampering: false,
  coachingEventDetailsUi: false,
  installerCsvUpload: false,
  showSpeedInfo: false,
  eventsSkiptripParam: false,
  eventsLimitParam: 10 | 100,
  postFactoStopSign: false,
  hitAndRun: false,
  roadRage: false,
  comboEvents: false,
  intersectionViolationWarning: false,
  newHome: false,
  customVideoRequest2: false,
  zendeskNewUrl: false,
  offlineCoaching: false,
  provisionerUserColumns: false,
  riskBasedTailgating: false,
  riskBasedDistraction: false,
  riskBasedViolations: false,
  riskBasedAbc: false,
  groupHierarchy: false,
  tailgatingOverlay: false,
  insightsManagerPerformance: false,
  driverRanking: false,
  repeatedEventsVideoRequest: false,
  sidebarFleetEntityCount: false,
  videoDownload: false,
  reportTemplates: false,
  pgE: false,
  newInsights2: false,
  insightsDriverSafety: false,
  defaultEventSeverity: 2,
  eventsDenyList: [],
  safetyIncidentEvent: false,
  cvrTimeRange: false,
  collisionReportMinDate: false,
  vehiclesModule: false,
  insightsCpmm: false,
};

export const defaultKebabCaseFlags = mapKeys(defaultFlags, (value, key) =>
  kebabCase(key),
);
