import useFleetData from 'hooks/use-fleet-data';
import useGroupData from 'hooks/use-group-data';
import * as API from '@nauto/api';
import { useQuery, useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { flatten } from 'lodash';

interface Params {
  fleetId: string;
  groupId: string;
}

const LIMIT = 20;

const useRequestedVideosQuery = (
  offset = 0,
): typeof requestedVideoDataQuery => {
  const { id: fleetId } = useFleetData({ current: true });
  const { id: groupId } = useGroupData();

  const getRequestedVideoData = async () => {
    const { data } = await API.cvr.fetchCVRRequestedVideos({
      fleetId: fleetId,
      groupId: groupId,
      limit: LIMIT,
      offset: offset,
    });
    return data;
  };

  const requestedVideoDataQuery = useQuery(
    ['useRequestedVideos', { fleetId, groupId }, offset],
    getRequestedVideoData,
    {
      staleTime: 60000,
      refetchOnMount: true,
      cacheTime: 0,
    },
  );

  return requestedVideoDataQuery;
};

type requestedVideosResponse = {
  data: {
    pages: API.cvr.CVRResponse[];
  };
  fetchNextPage: () => void;
  isLoading: boolean;
  hasNextPage?: boolean;
};

export const useRequestedVideosInfiniteQuery = (): requestedVideosResponse => {
  const { id: fleetId } = useFleetData({ current: true });
  const { id: groupId } = useGroupData();

  const getRequestedVideoData = async ({ pageParam = 0 }) => {
    const { data } = await API.cvr.fetchCVRRequestedVideos({
      fleetId: fleetId,
      groupId: groupId,
      limit: LIMIT,
      offset: pageParam,
    });
    return data;
  };

  return useInfiniteQuery(
    ['useRequestedVideosInfinite', { fleetId, groupId }],
    getRequestedVideoData,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length < LIMIT) {
          return undefined;
        }
        return flatten(pages).length + LIMIT;
      },
      staleTime: 30000,
      refetchOnMount: true,
      cacheTime: 0,
    },
  );
};

export default useRequestedVideosQuery;
