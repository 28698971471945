import { VeraVersionType } from './vera-score-utils';
import { getBrowserLanguage } from './helpers';
import { LoggedInUserType } from 'constants/roles';

export enum Keys {
  API_EXTRAS = 'api/extras',
  CURRENT_FLEET_SERVICE_URL = 'auth/current_fleet_service_url',
  CURRENT_FLEET = 'auth/current_fleet',
  CURRENT_GROUP = 'auth/current_group',
  CURRENT_ENTITY = 'auth/current_entity',
  CURRENT_SUPERFLEET = 'auth/current_superfleet',
  CUSTOM_CONFIG = 'custom_config',
  DRIVERS = 'auth/drivers',
  FLEETS = 'auth/fleets',
  GROUPS = 'auth/groups',
  I18N = 'i18nextLng',
  TABLE_CONTEXT = 'table-context',
  TOKEN = 'auth/token',
  USER = 'auth/user',
  VEHICLES = 'auth/vehicles',
  CACHED_FLEET = 'cache/fleet',
  CACHED_GROUP = 'cache/group',
  CACHED_REDIRECT = 'cache/redirect',
  CACHED_PREVLOCATION = 'cache/prevlocation',
  CACHED_SHOW_FEATURE_UPDATE_BANNER = 'cache/show_feature_update_banner',
  CACHED_VERA_SCORE_TYPE = 'cache/vera_score_type_new',
  CACHED_MAP_EVENTS_SHOW_CONFIG = 'cache/map_events_show_config',
  GLOBAL_ROLES = 'auth/global_roles',
  CACHED_LOGGED_IN_USER_TYPE = 'cache/logged_in_user_type',
  DELEGATED_LOGIN_USER_EMAIL = 'user/delegated_login_user_email',
  CACHED_MGR_PERFORMANCE_TRENDS = 'cache/manager_performance_trends',
}

const NOT_SUPPORTED_MESSAGE = 'Localstorage not supported';

/**
 * Set an item in localStorage
 * @param {string} key
 * @param {any} value
 */
export const lsSet = (key: string, value: any): void => {
  try {
    if (typeof value === 'string') {
      return localStorage.setItem(key, value);
    } else {
      return localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Retrieve an item from localStorage
 * @param {string} key
 */
export const lsGet = (key: Keys) => {
  try {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Clear the user items from localStorage
 */
export const clearUser = (): void => {
  try {
    localStorage.removeItem(Keys.DRIVERS);
    localStorage.removeItem(Keys.FLEETS);
    localStorage.removeItem(Keys.GROUPS);
    localStorage.removeItem(Keys.TOKEN);
    localStorage.removeItem(Keys.USER);
    localStorage.removeItem(Keys.VEHICLES);
    localStorage.removeItem(Keys.GLOBAL_ROLES);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Clear out the fleet related data
 */
export const clearFleetData = (): void => {
  try {
    localStorage.removeItem(Keys.CURRENT_FLEET);
    localStorage.removeItem(Keys.CURRENT_GROUP);
    localStorage.removeItem(Keys.DRIVERS);
    localStorage.removeItem(Keys.GROUPS);
    localStorage.removeItem(Keys.VEHICLES);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Clear out the cache (will prevent the next reload from defaulting to this fleet)
 */
export const clearFleetCacheData = (): void => {
  try {
    localStorage.removeItem(Keys.CURRENT_FLEET);
    localStorage.removeItem(Keys.CURRENT_GROUP);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Clear the table-context from localStorage
 */
export const clearTableContext = (): void => {
  try {
    localStorage.removeItem(Keys.TABLE_CONTEXT);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Returns the fleet ID from localStorage
 */
export const getFleetId = () => {
  const fleetId = lsGet(Keys.CURRENT_FLEET);
  return fleetId ? fleetId.toString() : null;
};

export const getGlobalRoles = () => {
  const roles = lsGet(Keys.GLOBAL_ROLES);
  return roles || [];
};

/**
 * Returns the fleet ID from localStorage
 */
export const getServiceUrl = (version = 'v2.2') => {
  const root =
    lsGet(Keys.CURRENT_FLEET_SERVICE_URL) || 'https://api.nauto.systems';
  return `${root}/${version}`;
};

/**
 * Returns the last fleet ID accessed by the user
 */
export const getCachedFleetId = () => lsGet(Keys.CACHED_FLEET);

/**
 * Returns the group ID from localStorage
 */
export const getGroupId = () => {
  const groupId = lsGet(Keys.CURRENT_GROUP);
  return groupId ? groupId.toString() : null;
};

/**
 * Returns the last group ID accessed by the user
 */
export const getCachedGroupId = () => lsGet(Keys.CACHED_GROUP);

/**
 * Return the fleets the current user belongs to
 */
export const getFleets = () => lsGet(Keys.FLEETS);

/**
 * Return the groups tree
 */
export const getGroups = () => lsGet(Keys.GROUPS);

/**
 * Return the current fleet's drivers list
 */
export const getDrivers = () => lsGet(Keys.DRIVERS);

/**
 * Return the current fleet's vehicles list
 */
export const getVehicles = () => lsGet(Keys.VEHICLES);

/**
 * Retrieve the user's API Token
 */
export const getToken = () => lsGet(Keys.TOKEN);

/**
 * Retrieve the current user
 */
export const getUser = () => lsGet(Keys.USER);

/**
 * Retrieve the cached redirect
 *  @returns {string}
 */
export const getRedirect = (): string => lsGet(Keys.CACHED_REDIRECT);

/**
 * Set cached redirect
 */
export const setRedirect = (redirect: string): void =>
  lsSet(Keys.CACHED_REDIRECT, redirect);

/**
 * Clear cached redirect
 */
export const clearRedirect = (): void => {
  try {
    localStorage.removeItem(Keys.CACHED_REDIRECT);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

interface PrevLocation {
  pathname: string;
  routeName: string;
  search: string;
}

/**
 * Retrieve the cached previous location
 */
export const getPrevLocation = (): PrevLocation =>
  lsGet(Keys.CACHED_PREVLOCATION);

/**
 * Set cached previous location
 */
export const setPrevLocation = (prevLocation: PrevLocation): void =>
  lsSet(Keys.CACHED_PREVLOCATION, prevLocation);

/**
 * Clear cached previous location
 */
export const clearPrevLocation = (): void => {
  try {
    localStorage.removeItem(Keys.CACHED_PREVLOCATION);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/* tslint:disable:bool-param-default */
/**
 * Retrieve the user's locale from localstorage
 * @returns {string}
 */
export const getLocale = (fullLocale?: boolean): string => {
  const locale = lsGet(Keys.I18N) || getBrowserLanguage();

  if (fullLocale) {
    const localeMap = {
      en: 'en-US',
      'fr-CA': 'en-US',
      de: 'de-DE',
      ja: 'ja-JP',
    };
    // sometimes the browser will return the locale in full by default (ex: chrome)
    // When this happens there will not be a value found in localeMap so we just return the already full locale
    return localeMap[locale] || locale;
  } else {
    return locale;
  }
};
/* tslint:enable:bool-param-default */

/**
 * Retrieve api extras
 */
export const getApiExtras = () => lsGet(Keys.API_EXTRAS);

/**
 * Retrieve the cached feature update banner display
 *  @returns {string}
 */
export const getDisplayFeatureUpdateBanner = (): boolean =>
  lsGet(Keys.CACHED_SHOW_FEATURE_UPDATE_BANNER);

/**
 * Set cached feature update banner display
 */
export const setDisplayFeatureUpdateBanner = (showBanner: boolean): void =>
  lsSet(Keys.CACHED_SHOW_FEATURE_UPDATE_BANNER, showBanner);

/**
 * Clear cached feature update banner display
 */
export const clearDisplayFeatureUpdateBanner = (): void => {
  try {
    localStorage.removeItem(Keys.CACHED_SHOW_FEATURE_UPDATE_BANNER);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Retrieve the cached score type
 *  @returns {string}
 */
export const getVeraScoreType = (): VeraVersionType =>
  lsGet(Keys.CACHED_VERA_SCORE_TYPE);

/**
 * Set cached vera score type
 */
export const setVeraScoreType = (scoreType: VeraVersionType): void =>
  lsSet(Keys.CACHED_VERA_SCORE_TYPE, scoreType);

export const getMapsEventsConfig = (): boolean =>
  lsGet(Keys.CACHED_MAP_EVENTS_SHOW_CONFIG);
export const setMapsEventsConfig = (value: boolean): void =>
  lsSet(Keys.CACHED_MAP_EVENTS_SHOW_CONFIG, value);
export const clearMapsEventsConfig = (): void => {
  try {
    localStorage.removeItem(Keys.CACHED_MAP_EVENTS_SHOW_CONFIG);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Retrieve the cached logged in user type
 *  @returns {string}
 */
export const getLoggedInUserType = (): LoggedInUserType =>
  lsGet(Keys.CACHED_LOGGED_IN_USER_TYPE);
/**
 * Set cached logged in user type
 */
export const setLoggedInUserType = (userType: LoggedInUserType): void =>
  lsSet(Keys.CACHED_LOGGED_IN_USER_TYPE, userType);
export const clearLoggedInUserType = (): void => {
  try {
    localStorage.removeItem(Keys.CACHED_LOGGED_IN_USER_TYPE);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Store the delegated user account and current user account
 */
export const getDelegatedLoginUserEmail = () =>
  lsGet(Keys.DELEGATED_LOGIN_USER_EMAIL);

export const setDelegatedLoginUserEmail = (email: string): void => {
  lsSet(Keys.DELEGATED_LOGIN_USER_EMAIL, email);
  lsSet(Keys.GLOBAL_ROLES, []);
  lsSet(Keys.CACHED_PREVLOCATION, '');
  lsSet(Keys.CURRENT_FLEET, '');
};

export const clearDelegatedLoginUserEmail = (): void => {
  try {
    localStorage.removeItem(Keys.DELEGATED_LOGIN_USER_EMAIL);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};

/**
 * Store the visible trends graphs on manager performance page
 */
export const getManagerPerformanceVisibleGraphs = (): string[] =>
  lsGet(Keys.CACHED_MGR_PERFORMANCE_TRENDS);

export const setManagerPerformanceVisibleGraphs = (
  visibleIds: string[],
): void => {
  lsSet(Keys.CACHED_MGR_PERFORMANCE_TRENDS, visibleIds);
};

export const clearManagerPerformanceVisibleGraphs = (): void => {
  try {
    localStorage.removeItem(Keys.CACHED_MGR_PERFORMANCE_TRENDS);
  } catch (e) {
    console.log(NOT_SUPPORTED_MESSAGE);
  }
};
