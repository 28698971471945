import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Icon } from '@nauto/uikit/dist/atoms/icons';
import { IconButton } from '@nauto/uikit/dist/atoms/buttons';
import { toggleInfoModal } from './notifications.redux';
import { useTranslation } from 'react-i18next';
import Notification from './notification';
import FleetLink, { fleetLink } from 'components/fleet-link';
import { ROUTES } from 'routes/constants';
import NotificationsInfoModal from './notifications-info-modal';
import { spaceSizesPx } from '@nauto/core';
import { colors } from '@nauto/uikit/dist/styles';
import useFeatureFlags from 'hooks/use-feature-flags';
import { usePaginatedFaces } from 'hooks/use-faces-data/queries';
import useUserPermissions, { Permissions } from 'hooks/use-permissions';

interface Props {
  handleToggle?: () => void;

  className?: string;
}

const TaggingNotificationRow: React.FC<Props> = ({
  handleToggle,
  className,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags();
  const { hasPermission } = useUserPermissions();
  const isNautoEssential = hasPermission(Permissions.NautoEssential);
  const { data: paginatedFaces } = usePaginatedFaces();
  const { hasMoreUntaggedFaces, untaggedFacesCount } = paginatedFaces || {};
  const hasUntaggedFaces = !isNautoEssential && untaggedFacesCount > 0;

  const showInfoModal = e => {
    e.stopPropagation();
    dispatch(toggleInfoModal(true));
    handleToggle();
  };

  const label = hasMoreUntaggedFaces
    ? t("We've seen {{count}} or more new faces recently", {
        count: untaggedFacesCount,
      })
    : t("We've seen {{count}} new faces recently", {
        count: untaggedFacesCount,
      });

  return (
    hasUntaggedFaces && (
      <>
        <Notification
          icon={
            <div className="circle">
              <Icon icon="user" size="md" baseColor={colors.grey900} />
            </div>
          }
          dismissible={false}
          id={'client-generated-tagging-notification'}
          link={fleetLink(ROUTES.TAG)}
          onLinkClick={handleToggle}
          className={className}
        >
          <div>
            <div>{label}</div>
            <FleetLink
              to={{
                pathname: ROUTES.TAG,
              }}
            >
              <div className="cta">{t('Click to tag your drivers')}</div>
            </FleetLink>
            {featureFlags.taggingHelpModal && (
              <div className="info-icon">
                <IconButton
                  icon="info"
                  size="md"
                  baseColor={colors.grey900}
                  activeColor={colors.black}
                  onClick={showInfoModal}
                />
              </div>
            )}
          </div>
        </Notification>
        <NotificationsInfoModal />
      </>
    )
  );
};

const StyledComponent = styled(TaggingNotificationRow)`
  cursor: pointer;
  .content-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    .info-icon {
      margin-left: auto;
    }
  }
  .circle {
    background: ${colors.grey100};
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: ${spaceSizesPx.sm};
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .cta {
    font-family: Circular-Bold;
  }
`;

export default StyledComponent;
