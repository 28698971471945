import config from 'utils/config';
import { getUser } from 'utils/localstorage';
import { fetchRequest } from 'utils/requests';
import { AlertType } from 'constants/alert-types';
import { AlertResponse, AlertNotification } from './types';
import { mapAlertResponseToNotifications } from './utils';

const getAlerts = (): Promise<AlertNotification[]> => {
  const userId = getUser().id;
  const url = `${config.admin_api_url}/alerts/users/${userId}`;

  return fetchRequest(url, { method: 'GET' }).then((alerts: AlertResponse) =>
    mapAlertResponseToNotifications(alerts, [
      AlertType.Braking,
      AlertType.Distraction,
      AlertType.Tailgating,
    ]),
  );
};

const postAlert = ({ body }): Promise<AlertNotification[]> => {
  const userId = getUser().id;
  const url = `${config.admin_api_url}/alerts/users/${userId}`;

  return fetchRequest(url, { method: 'POST', body }).then(alerts =>
    mapAlertResponseToNotifications(alerts, [
      AlertType.Braking,
      AlertType.Distraction,
      AlertType.Tailgating,
    ]),
  );
};

export default {
  postAlert,
  getAlerts,
};
