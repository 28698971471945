import React from 'react';
import styled from 'styled-components';
import * as API from '@nauto/api';
import { Icon } from '@nauto/uikit/dist/atoms/icons';
import { Size } from '@nauto/uikit/dist/types';
import { sizes } from '@nauto/uikit/dist/styles';
import { colors } from '@nauto/core';
import { eventDisplayMap, Kind } from 'components/events/event-types';
import {
  BackingupIcon,
  StopsignIcon,
  IntersectionViolationWarningIcon,
} from '@nauto/icons';
import {
  isCollision,
  isNearCollision,
  isDrowsiness,
  isSpeeding,
  isBackingUp,
  hasStopSignViolation,
  hasRollingStopSignViolation,
  hasIntersectionViolationWarning,
} from 'components/events/utils';
import useFeatureFlags from 'hooks/use-feature-flags';

export interface Props {
  event?: API.events.Event;
  eventType: string;
  className?: string;
  size?: Size;
}

const EventCoin: React.FC<Props> = ({
  event,
  size = 'md',
  eventType = 'acceleration',
  className,
}) => {
  const featureFlags = useFeatureFlags();
  const collisionType = { icon: 'collision', kind: Kind.collision };
  const nearCollisionType = { icon: 'nearCollision', kind: Kind.risk };
  const drowsinessType = { icon: 'drowsy', kind: Kind.risk };
  const speedingType = { icon: 'speed', kind: Kind.risk };
  const backingUpType = { Icon: BackingupIcon, kind: Kind.violation };
  const defaultType = { icon: 'distraction', kind: Kind.risk };
  const stopSignType = {
    Icon: StopsignIcon,
    kind: Kind.violation,
  };

  const intersectionViolationWarningType = {
    Icon: IntersectionViolationWarningIcon,
    kind: Kind.risk,
  };

  const { icon, Icon: EventIcon, kind } =
    event && isCollision(event)
      ? collisionType
      : event && featureFlags.nearCollisionEvents && isNearCollision(event)
      ? nearCollisionType
      : event && featureFlags.drowsiness && isDrowsiness(event)
      ? drowsinessType
      : event && featureFlags.postedSpeedLimit && isSpeeding(event)
      ? speedingType
      : event && featureFlags.backingUpViolation && isBackingUp(event)
      ? backingUpType
      : (event &&
          featureFlags.postFactoStopSign &&
          hasStopSignViolation(event)) ||
        hasRollingStopSignViolation(event)
      ? stopSignType
      : event &&
        featureFlags.intersectionViolationWarning &&
        hasIntersectionViolationWarning(event)
      ? intersectionViolationWarningType
      : eventDisplayMap[eventType] || defaultType;

  return (
    <div
      className={`${className} ${size}`}
      style={{ background: colors[kind] }}
    >
      {EventIcon ? (
        <EventIcon size={size} color={colors.white} />
      ) : (
        <Icon size={size} icon={icon} baseColor={colors.white} />
      )}
    </div>
  );
};

export default styled(EventCoin)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 1000px;
  ${sizes.createRules(sizes.coin)};
`;
