import { ROLES } from 'constants/roles';
import { FEATURES, PACKAGE } from 'components/feature/constants';

export enum Permissions {
  Home = 'home',
  Insights = 'insights',
  NewInsightsLanding = 'newInsightsLanding', //landing page
  Trips = 'trips',
  Events = 'events',
  Reports = 'reports',
  Organization = 'organization',
  Coaching = 'coaching',
  WriteEvents = 'writeEvents',
  SuperfleetInsights = 'superfleetInsights',
  SuperfleetEvents = 'superfleetEvents',
  SuperfleetOrganization = 'superfleetOrganization',
  SuperfleetReports = 'superfleetReports',
  DriverInsights = 'driverInsights',
  DriverInsightsHome = 'driverInsightsHome',
  DriverManagement = 'driverManagement',
  DriverEvents = 'driverEvents',
  DriverMaps = 'driverMaps',
  DriverReports = 'driverReports',
  VehicleInsights = 'vehicleInsights',
  VehicleInsightsHome = 'vehicleInsightsHome',
  VehicleManagement = 'VehicleManagement',
  VehicleEvents = 'vehicleEvents',
  VehicleMaps = 'vehicleMaps',
  VehicleReports = 'VehicleReports',
  UserManagement = 'userManagement',
  RoleManagement = 'roleManagement',
  FleetSettings = 'fleetSettings',
  Developer = 'developer',
  HideExteriorVideo = 'exteriorVideo',
  HideInteriorVideo = 'interiorVideo',
  ReadOnlyEventsRole = 'readOnlyEventsRole',
  CoachingEventDetailsUI = 'coachingEventDetails',
  EventDetailsUI = 'eventDetails',
  InternalUsers = 'internalUsers',
  CustomUserManagement = 'customUserManagement',
  InstallerCsvUpload = 'installerCsvUpload',
  CustomCoaching = 'customCoaching',
  CustomReports = 'custom_reports',
  ReportsCollision = 'reportsCollision',
  ReportsAtRisk = 'reportsAtRisk',
  ReportsDeviceHealth = 'reportsDeviceHealth',
  ReportsRealTimeEffectiveness = 'reportsRealTimeEffectiveness',
  ReportsPolicyViolations = 'reportsPolicyViolations',
  ReportsCoachingEffectiveness = 'reportsCoachingEffectiveness',
  ReportsTopPerforming = 'reportsTopPerforming',
  ReportsCustomReports = 'reportsCustomReports',
  TagDriver = 'tagDriver',
  VideoRequest = 'videoRequest',
  config = 'config',
  CVR = 'CVR',
  NautoEssential = 'nautoEssential',
  CoachingPackage = 'CoachingPackage',
  DownloadVideo = 'downloadVideo',
  ManagerPerformance = 'managerPerformance',
  DriverSafety = 'driverSafety',
}

const featureFlags = {
  [Permissions.Home]: {
    ldId: 'newHome',
    fleet: {
      features: [FEATURES.HOME],
    },
  },
  [Permissions.Insights]: {
    fleet: {
      features: [FEATURES.DRIVER_INSIGHTS, FEATURES.VEHICLE_INSIGHTS],
    },
  },
  [Permissions.Trips]: {
    fleet: {
      features: [FEATURES.TRIPS],
    },
  },
  [Permissions.Events]: {
    fleet: {
      features: [FEATURES.EVENTS],
    },
  },
  [Permissions.Reports]: {
    fleet: {
      features: [FEATURES.REPORTS],
    },
  },
  [Permissions.Organization]: {
    fleet: {
      features: [FEATURES.VEHICLE_MANAGEMENT, FEATURES.DRIVER_MANAGEMENT],
    },
  },
  [Permissions.Coaching]: {
    ldId: 'coaching',
    fleet: {
      features: [FEATURES.DRIVER_COACHING],
    },
  },
  [Permissions.WriteEvents]: {
    roles: [
      ROLES.SYSADMIN,
      ROLES.GLOBAL_INSTALLER,
      ROLES.MANUFACTURER,
      ROLES.REVIEWER,
      ROLES.SERVICE,
      ROLES.NETSUITE,
      ROLES.SUPERFLEET_CHILD,
      ROLES.GLOBAL_ADMIN,
      ROLES.CONFIG_VIEWER,
      ROLES.CONFIG_EDITOR,
      ROLES.CONFIG_PUSHER,
      ROLES.PROFILE_VIEWER,
      ROLES.PROFILE_EDITOR,
      ROLES.FLEET_ADMIN,
      ROLES.FLEET_MANAGER,
      ROLES.HIDDEN_SUPPORT_FLEET_ADMIN,
      ROLES.FLEET_DATA_SERVICE_ADMIN,
      ROLES.FLEET_DATA_SERVICE,
      ROLES.FLEET_INSTALLER,
      ROLES.SUPERFLEET_FULL_ACCESS,
      ROLES.SUPERFLEET_SUMMARY,
      ROLES.DRIVER_ID_DEBUG_TOOL,
    ],
  },
  [Permissions.SuperfleetInsights]: {
    roles: [
      ROLES.SUPERFLEET_FULL_ACCESS,
      ROLES.SUPERFLEET_SUMMARY,
      ROLES.GLOBAL_ADMIN,
    ],
  },
  [Permissions.SuperfleetEvents]: {
    ldId: 'superfleetEvents',
    roles: [
      ROLES.SUPERFLEET_FULL_ACCESS,
      ROLES.SUPERFLEET_EVENTS,
      ROLES.GLOBAL_ADMIN,
    ],
  },
  [Permissions.SuperfleetOrganization]: {
    roles: [
      ROLES.SUPERFLEET_FULL_ACCESS,
      ROLES.SUPERFLEET_SUMMARY,
      ROLES.GLOBAL_ADMIN,
    ],
  },
  [Permissions.SuperfleetReports]: {
    roles: [
      ROLES.SUPERFLEET_FULL_ACCESS,
      ROLES.SUPERFLEET_SUMMARY,
      ROLES.GLOBAL_ADMIN,
    ],
  },
  [Permissions.DriverInsights]: {
    roleId: 'driver_insights',
  },
  [Permissions.DriverInsightsHome]: {
    roleId: 'driver_insights.insights',
  },
  [Permissions.DriverManagement]: {
    roleId: 'driver_insights.fleet',
  },
  [Permissions.DriverEvents]: {
    roleId: 'driver_insights.events',
  },
  [Permissions.DriverMaps]: {
    roleId: 'driver_insights.maps',
  },
  [Permissions.DriverReports]: {
    roleId: 'driver_insights.reports',
  },
  [Permissions.VehicleInsights]: {
    roleId: 'vehicle_insights',
  },
  [Permissions.VehicleInsightsHome]: {
    roleId: 'vehicle_insights.insights',
  },
  [Permissions.VehicleManagement]: {
    roleId: 'vehicle_insights.fleet',
  },
  [Permissions.VehicleEvents]: {
    roleId: 'vehicle_insights.events',
  },
  [Permissions.VehicleMaps]: {
    roleId: 'vehicle_insights.maps',
  },
  [Permissions.VehicleReports]: {
    roleId: 'vehicle_insights.maps_reports',
  },
  [Permissions.UserManagement]: {
    roleId: 'user_management',
  },
  [Permissions.RoleManagement]: {
    roleId: 'role_management',
  },
  [Permissions.FleetSettings]: {
    roleId: 'fleet_settings',
  },
  [Permissions.Developer]: {
    roleId: 'developer',
  },
  [Permissions.CustomCoaching]: {
    roleId: 'coaching',
  },
  [Permissions.HideExteriorVideo]: {
    ldId: 'noOutwardVideo',
    fleet: {
      properties: ['out_camera_media_disabled'],
    },
  },
  [Permissions.HideInteriorVideo]: {
    fleet: {
      properties: ['in_camera_media_disabled'],
      features: [FEATURES.DISABLE_INTERIOR_VIDEO],
    },
  },
  [Permissions.ReadOnlyEventsRole]: {
    roles: [ROLES.SUPERFLEET_EVENTS],
    exactRoleMatch: true,
  },
  [Permissions.CoachingEventDetailsUI]: {
    ldId: 'coachingEventDetailsUi',
  },
  [Permissions.EventDetailsUI]: {
    ldId: 'eventDetailsUi',
  },
  [Permissions.InternalUsers]: {
    roles: [ROLES.GLOBAL_ADMIN],
  },
  [Permissions.CustomUserManagement]: {
    ldId: 'customUserManagement',
  },
  [Permissions.InstallerCsvUpload]: {
    ldId: 'installerCsvUpload',
    roles: [ROLES.GLOBAL_INSTALLER, ROLES.GLOBAL_ADMIN],
    containAllRoles: true,
  },

  [Permissions.CustomReports]: {
    roleId: 'reports',
  },
  [Permissions.ReportsCollision]: {
    roleId: 'reports.collision',
  },
  [Permissions.ReportsAtRisk]: {
    roleId: 'reports.at_risk',
  },
  [Permissions.ReportsDeviceHealth]: {
    roleId: 'reports.device_health',
  },
  [Permissions.ReportsRealTimeEffectiveness]: {
    roleId: 'reports.real_time_effectiveness',
  },
  [Permissions.ReportsPolicyViolations]: {
    roleId: 'reports.policy_violations',
  },
  [Permissions.ReportsCoachingEffectiveness]: {
    roleId: 'reports.coaching_effectiveness',
  },
  [Permissions.ReportsTopPerforming]: {
    roleId: 'reports.top_performing',
  },

  [Permissions.ReportsCustomReports]: {
    roleId: 'reports.custom',
  },
  [Permissions.TagDriver]: {
    roleId: 'tag_driver',
  },
  [Permissions.VideoRequest]: {
    roleId: 'video_request',
  },
  [Permissions.config]: {
    roleId: 'config',
  },
  [Permissions.CVR]: {
    ldId: 'customVideoRequest2',
  },
  [Permissions.NautoEssential]: {
    fleet: {
      packages: [PACKAGE.ESSENTIAL],
    },
  },
  [Permissions.CoachingPackage]: {
    ldId: 'coaching',
    fleet: {
      packages: [
        PACKAGE.COACH_LEGACY,
        PACKAGE.MANAGER_LED_COACHING,
        PACKAGE.PREVENT_PRO,
        PACKAGE.PREVENT_ENTERPRISE,
        PACKAGE.NAUTO_PREMIUM,
      ],
    },
  },
  [Permissions.DownloadVideo]: {
    roleId: 'download_video',
  },
  [Permissions.ManagerPerformance]: {
    ldId: 'insightsManagerPerformance',
  },
  [Permissions.DriverSafety]: {
    ldId: 'insightsDriverSafety',
  },
  [Permissions.NewInsightsLanding]: {
    ldId: 'newInsights2',
    fleet: {
      features: [FEATURES.DRIVER_INSIGHTS, FEATURES.VEHICLE_INSIGHTS],
    },
  },
};

export default featureFlags;
