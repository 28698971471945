import * as API from '@nauto/api';
import { TimeIntervals } from '@nauto/date-picker';
import { GridReportEntityType } from 'components/entity-type/entity-type-utils';
import { DurationType, ReportRowAPIData } from 'components/reports/types';
import useFleetData from 'hooks/use-fleet-data';
import useVeraScoreToggle from 'hooks/use-vera-score-type-toggle';
import { useQuery } from 'react-query';
import { getRangePresetType, Range } from 'utils/date-ranges';
import { ScoreVersion } from 'utils/vera-score-utils';
import { atRiskVera2Threshold, atRiskVera3Threshold } from 'constants/event-thresholds';
import { topPerformerThreshold } from 'utils/report-utils';
import { languageIsJapanese } from 'utils/helpers';
import { Group } from 'components/groups/types';

interface QueryProps {
  range: Range;
  dateFilter: TimeIntervals;
  isScoreVersionVera3: boolean;
}

interface QueryPropsPayload {
  reportType?: API.reports.REPORT_TYPES;
  min?: number;
  max?: number;
  range?: {
    min?: number;
    max?: number;
  };
  version?: ScoreVersion;
  scoreOnly?: boolean;
  minScore?: number;
  maxScore?: number;
  term?: DurationType;
}

interface Params {
  reportType: API.reports.REPORT_TYPES;
  range: Range;
  entity: GridReportEntityType;
  dateFilter: TimeIntervals;
  groupId: string;
  subfleetsSelected?: boolean;
  indexedGroups?: any[] | Group;
  cleanSubfleetsReportsData?: (reportData: API.reports.ReportItem[] | ReportRowAPIData[], indexedGroups?: any[] | Group, isScoreVersionVera3?: boolean, collisionEventType?: string) => any;
  cleanReportsData?: (reportData: API.reports.ReportItem[] | ReportRowAPIData[], indexedGroups?: any[] | Group, isScoreVersionVera3?: boolean, collisionEventType?: string) => any;
  collisionEventType?: string;
}

interface ReportPageQueryPayload {
  data: ReportRowAPIData[] | API.reports.ReportItem[];
  isLoading: boolean;
}

type ReportPageQuery = (enabled?: boolean) => ReportPageQueryPayload;

interface UseReportPagePayload {
  reportPageQuery: ReportPageQuery;
}
const REPORT_TYPES = API.reports.REPORT_TYPES;

export const queries = ({ range, dateFilter, isScoreVersionVera3 }: QueryProps): Record<API.reports.REPORT_TYPES, QueryPropsPayload> => ({
  [REPORT_TYPES.COLLISION]: {
    reportType: REPORT_TYPES.COLLISION,
    min: range.min.valueOf(),
    max: range.max.valueOf(),
  },
  [REPORT_TYPES.RTA]: {
    reportType: REPORT_TYPES.RTA,
    min: range.min.valueOf(),
    max: range.max.valueOf(),
  },
  [REPORT_TYPES.CUSTOM_REPORT]: {
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    term: getRangePresetType(range.min, range.max),
    ...(isScoreVersionVera3 ? { version: ScoreVersion.VERA3 } : {}),
  },
  [REPORT_TYPES.TOP_PERFORMING]: {
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    scoreOnly: true,
    term: dateFilter === TimeIntervals.WEEKLY ? API.drivers.DurationType.Weekly : API.drivers.DurationType.Monthly,
    ...(isScoreVersionVera3 ? { version: ScoreVersion.VERA3 } : {}),
    ...(![TimeIntervals.QUARTERLY, TimeIntervals.YEARLY].includes(dateFilter)
      ? {
          minScore: topPerformerThreshold,
        }
      : {}),
  },
  [REPORT_TYPES.AT_RISK]: {
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    scoreOnly: true,
    term: dateFilter === TimeIntervals.WEEKLY ? API.drivers.DurationType.Weekly : API.drivers.DurationType.Monthly,
    ...(isScoreVersionVera3 ? { version: ScoreVersion.VERA3 } : {}),
    ...(![TimeIntervals.QUARTERLY, TimeIntervals.YEARLY].includes(dateFilter)
      ? {
          minScore: 0,
          maxScore: languageIsJapanese() ? atRiskVera2Threshold : atRiskVera3Threshold,
        }
      : {}),
  },
  [REPORT_TYPES.POLICY_VIOLATIONS]: {
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    term: dateFilter === TimeIntervals.WEEKLY ? API.drivers.DurationType.Weekly : API.drivers.DurationType.Monthly,
    ...(isScoreVersionVera3 ? { version: ScoreVersion.VERA3 } : {}),
  },
});

const useReportPageQuery = (initialParams: Params): UseReportPagePayload => {
  const { reportType, range, dateFilter, entity, groupId, subfleetsSelected, indexedGroups, cleanSubfleetsReportsData, cleanReportsData, collisionEventType } = initialParams;
  const { isScoreVersionVera3 } = useVeraScoreToggle();
  const { id: fleetId } = useFleetData({ current: true });

  const queryParams = {
    ...queries({ range, dateFilter, isScoreVersionVera3 })[reportType],
    entity,
    fleetId,
    groupId,
  };

  const getReportData = async (): Promise<ReportRowAPIData[] | API.reports.ReportItem[]> => {
    let APIRequest;
    if ([REPORT_TYPES.RTA, REPORT_TYPES.COLLISION].includes(reportType)) {
      APIRequest = API.reports.getNewReportData(queryParams);
    } else {
      APIRequest = API.reports.getReport(queryParams);
    }
    const { data } = await APIRequest;

    let updatedData;
    if (reportType === REPORT_TYPES.CUSTOM_REPORT) {
      if (subfleetsSelected) {
        updatedData = cleanSubfleetsReportsData(data as API.reports.ReportItem[], indexedGroups, isScoreVersionVera3, collisionEventType);
      } else {
        updatedData = await cleanReportsData(data as API.reports.ReportItem[], null, isScoreVersionVera3, collisionEventType).then(data => JSON.parse(data));
      }
    } else if (reportType === REPORT_TYPES.TOP_PERFORMING) {
      updatedData = data.filter(data => data.agg_score.risk_scores.risk_score >= topPerformerThreshold);
    } else if (reportType === REPORT_TYPES.AT_RISK) {
      updatedData = data.filter(data => data.agg_score.risk_scores.risk_score > -1 && data.agg_score.risk_scores.risk_score <= atRiskVera3Threshold);
    } else {
      updatedData = data;
    }

    return updatedData as ReportRowAPIData[];
  };

  const useReportPageData: ReportPageQuery = (enabled = true) => {
    const { data = [], isLoading } = useQuery(['getReportPageData', queryParams, dateFilter, Object.keys(indexedGroups)], getReportData, {
      staleTime: Infinity,
      enabled: !!queryParams && enabled,
    });

    return {
      data,
      isLoading,
    };
  };

  return { reportPageQuery: useReportPageData };
};

export default useReportPageQuery;
