import * as API from '@nauto/api';
import { useQuery } from 'react-query';
import { THIRTY_MINUTES } from '../constants';

const useDriverCountQuery = ({
  fleetId,
  groupId,
}: {
  fleetId: string;
  groupId: string;
}): any => {
  const getDriverCountData = async () => {
    const { data } = await API.reports.getDriverCount({
      fleetId,
      groupId,
    });
    return data;
  };

  const { data, isLoading, isError } = useQuery(
    ['getDriverCountData', fleetId, groupId],
    getDriverCountData,
    {
      cacheTime: THIRTY_MINUTES,
      staleTime: Infinity,
    },
  );

  return {
    data,
    isLoading,
    isError,
  };
};

export default useDriverCountQuery;
