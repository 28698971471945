import * as API from '@nauto/api';
import { Recipe } from '@nauto/api/lib/events';
import { UnifiedCoachingLabels } from 'constants/coaching';
import { COACHING_LABELS } from 'constants/events';

const clientEvent = 'client-event-type';

export const withSeverity = (
  severity: number,
): { severity: nauto.Severity[] } => ({
  severity: [
    'high',
    ...((severity <= 2 ? ['med'] : []) as nauto.Severity[]),
    ...((severity === 1 ? ['low'] : []) as nauto.Severity[]),
  ],
});

export const withMediaLockedOut = (
  repeatedEvents = false,
): { media_locked_out?: boolean } =>
  repeatedEvents ? { media_locked_out: false } : {};

export const withRisk = (event: string): Record<string, string>[] => [
  {
    name: 'event-type',
    value: event,
  },
  {
    name: 'customer-facing',
    value: 'true',
  },
];

export const buildCoachingLabels = (
  coachingLabel = '',
): Record<string, string | number | boolean>[] => {
  switch (coachingLabel) {
    case COACHING_LABELS.COACHABLE:
      return [{ name: 'coaching-status', value: 'uncoached' }];
    case COACHING_LABELS.UNCOACHABLE:
      return [{ name: 'coaching-status', value: 'uncoachable' }];
    case COACHING_LABELS.COACHED:
      return [{ name: 'coaching-status', value: 'coached' }];
    default:
      return [];
  }
};

interface RecipeParams {
  coaching?: string;
  severity?: number;
  riskBased?: boolean;
}

interface DRecipeParams extends RecipeParams {
  repeatedEvents?: boolean;
}

type RecipeCreator = (params?: RecipeParams) => API.events.Recipe[];
type DRecipeCreator = (params?: DRecipeParams) => API.events.Recipe[];

export const marked = (coaching?: string): API.events.Recipe => ({
  eventTypes: ['mark', 'mark-panic'],
  labels: buildCoachingLabels(coaching),
});

export const requests = (coaching?: string): API.events.Recipe => ({
  eventTypes: ['custom-video-request'],
  labels: buildCoachingLabels(coaching),
});

export const collisions = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: 'collision', value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
  media: false,
});

export const cellPhone: RecipeCreator = ({
  severity,
  coaching,
  riskBased,
} = {}) => [
  riskBased
    ? {
        labels: [...withRisk('cell-phone'), ...buildCoachingLabels(coaching)],
        params: withSeverity(severity),
      }
    : {
        labels: [
          { name: clientEvent, value: 'cell-phone' },
          ...buildCoachingLabels(coaching),
        ],
        params: withSeverity(severity),
      },
];

export const smoking: RecipeCreator = ({
  severity,
  coaching,
  riskBased,
} = {}) => [
  riskBased
    ? {
        labels: [...withRisk('smoking'), ...buildCoachingLabels(coaching)],
        params: withSeverity(severity),
      }
    : {
        labels: [
          { name: clientEvent, value: 'smoking' },
          ...buildCoachingLabels(coaching),
        ],
        params: withSeverity(severity),
      },
];

export const seatbelt: RecipeCreator = ({
  severity,
  coaching,
  riskBased,
} = {}) => [
  riskBased
    ? {
        labels: [...withRisk('no-seat-belt'), ...buildCoachingLabels(coaching)],
        params: withSeverity(severity),
      }
    : {
        labels: [
          { name: clientEvent, value: 'no-seat-belt' },
          ...buildCoachingLabels(coaching),
        ],
        params: withSeverity(severity),
      },
];

/**
 * https://nautodev.atlassian.net/browse/NAUTO-48226
 * Obstructed is a new filter current not supported
 * in the fleet app, but adding recipe for when we
 * are ready to surface risk based obstructed events
 */
export const obstructed: RecipeCreator = () => [
  {
    labels: withRisk('obstructed'),
  },
];

export const negativeDriving: API.events.Recipe = {
  labels: [
    {
      name: UnifiedCoachingLabels.NegativeDriving,
      value: true,
    },
  ],
};

export const nearCollision = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.NearCollision, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const distraction: DRecipeCreator = ({
  severity,
  coaching,
  riskBased,
  repeatedEvents,
}) => [
  riskBased
    ? {
        labels: [
          ...withRisk('distraction-looking-down'),
          ...buildCoachingLabels(coaching),
        ],
        params: {
          ...withSeverity(severity),
          ...withMediaLockedOut(repeatedEvents),
        },
      }
    : {
        eventTypes: ['distraction'],
        labels: [
          { name: 'client-event-type', value: 'looking-down' },
          ...buildCoachingLabels(coaching),
        ],
        params: {
          ...withSeverity(severity),
          ...withMediaLockedOut(repeatedEvents),
        },
      },
];

export const acceleration: RecipeCreator = ({
  severity,
  coaching,
  riskBased,
}) => [
  riskBased
    ? {
        labels: [...withRisk('acceleration'), ...buildCoachingLabels(coaching)],
        params: withSeverity(severity),
      }
    : {
        eventTypes: ['acceleration', 'acceleration-hard'],
        labels: buildCoachingLabels(coaching),
        params: withSeverity(severity),
      },
];

export const braking: RecipeCreator = ({ severity, coaching, riskBased }) => [
  riskBased
    ? {
        labels: [...withRisk('braking'), ...buildCoachingLabels(coaching)],
        params: withSeverity(severity),
      }
    : {
        eventTypes: ['braking-hard', 'braking-severe'],
        labels: buildCoachingLabels(coaching),
        params: withSeverity(severity),
      },
];

export const cornering: RecipeCreator = ({ severity, coaching, riskBased }) => [
  riskBased
    ? {
        labels: [...withRisk('cornering'), ...buildCoachingLabels(coaching)],
        params: withSeverity(severity),
      }
    : {
        eventTypes: ['corner-left-hard', 'corner-right-hard'],
        labels: buildCoachingLabels(coaching),
        params: withSeverity(severity),
      },
];

export const tailgating: RecipeCreator = ({
  severity,
  riskBased,
  coaching,
}) => [
  riskBased
    ? {
        labels: [...withRisk('tailgating'), ...buildCoachingLabels(coaching)],
        params: withSeverity(severity),
      }
    : {
        eventTypes: ['tailgating'],
        labels: buildCoachingLabels(coaching),
        params: withSeverity(severity),
      },
];

export const forwardCollisionWarning = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.ForwardCollisionWarning, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const pedestrianCollisionWarning = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.PedestrianCollisionWarning, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const hitAndRun = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.HitAndRun, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const roadRage = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.RoadRage, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const stopSign = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.StopSign, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const rollingStopSign = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.RollingStopSign, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const drowsiness = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.Drowsiness, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const speeding = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.Speeding, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const backingUp = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.BackingUp, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const intersectionViolationWarning = (
  severity: number,
  coaching?: string,
): API.events.Recipe => ({
  labels: [
    { name: UnifiedCoachingLabels.IntersectionViolationWarning, value: 'true' },
    ...buildCoachingLabels(coaching),
  ],
  params: withSeverity(severity),
});

export const repeatedEvents = (severity: number): API.events.Recipe => ({
  params: {
    media_locked_out: true,
    ...withSeverity(severity),
  },
});

export const safetyIncident = (severity: number): API.events.Recipe => ({
  labels: [{ name: UnifiedCoachingLabels.SafetyIncident, value: 'true' }],
  params: withSeverity(severity),
});
