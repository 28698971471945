import React from 'react';
import styled from 'styled-components';
import connect from 'utils/connect';
import { colors } from '@nauto/uikit/dist/styles';
import { Icon } from '@nauto/uikit/dist/atoms/icons';
import { toggleInfoModal } from './notifications.redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import Notification from './notification';
import FleetLink, { fleetLink } from 'components/fleet-link';
import { ROUTES } from 'routes/constants';
import { unhealthyCountSelector } from 'components/organization/vehicles/vehicles.redux';
import { createStructuredSelector } from 'reselect';
import { spaceSizesPx } from '@nauto/core';

interface Props extends WithTranslation {
  className?: string;
  dispatch?: any;
  unhealthyCount: number;
  handleToggle?: () => any;
}

class HealthNotificationRow extends React.Component<Props> {
  showInfoModal = e => {
    e.stopPropagation();
    this.props.dispatch(toggleInfoModal(true));
    this.props.handleToggle();
  };

  render() {
    const { t, className, handleToggle, unhealthyCount } = this.props;
    return (
      <Notification
        icon={
          <div className="circle">
            <Icon icon="heart" size="md" baseColor={colors.white} />
          </div>
        }
        dismissible={true}
        id={'client-generated-tagging-notification'}
        link={fleetLink(ROUTES.FLEET_HEALTH)}
        onLinkClick={handleToggle}
        className={className}
      >
        <div>
          <div>
            {t('{{count}} device issues detected', { count: unhealthyCount })}
          </div>
          <FleetLink
            to={{
              pathname: ROUTES.FLEET_HEALTH,
            }}
          >
            <MoreDetails>{t('More details')}</MoreDetails>
          </FleetLink>
        </div>
      </Notification>
    );
  }
}

const MoreDetails = styled.div`
  color: ${colors.green500};
  user-select: none;
  &:hover {
    color: ${colors.green700};
  }
`;

const StyledComponent = styled(HealthNotificationRow)`
  cursor: pointer;
  .content-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    .info-icon {
      margin-left: auto;
    }
  }
  .circle {
    background: #ff9800;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: ${spaceSizesPx.sm};
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const selector = createStructuredSelector({
  unhealthyCount: unhealthyCountSelector,
});

export default connect(selector)(withTranslation()(StyledComponent));
