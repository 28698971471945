import React, { FC } from 'react';
import { connect } from 'react-redux';
import FeatureFlagsContext from './context';
import { Flags } from 'components/feature-flags/flags';

interface Props {
  featureFlags: Flags;
}

const FeatureFlagsProvider: FC<Props> = ({ children, featureFlags }) => {
  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const selector = ({ featureFlags }) => ({ featureFlags });

export default connect(selector)(FeatureFlagsProvider);
