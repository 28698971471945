import { Group } from 'components/groups/groups.redux';
import { colors } from '@nauto/core';

export const buildGroupTree = (group: Group) => ({
  ...group,
  children: (group.groups && group.groups.map(buildGroupTree)) || [],
});

// tslint:disable-next-line: no-shadowed-variable
export const findSelectedGroupPath = (
  selectedNodeId: string,
  rootGroup: Group,
) => {
  const trail: string[] = [];
  let found = false;
  const searchInGroup = (groups: any) => {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < groups.length; i++) {
      trail.push(groups[i].id);
      if (groups[i].id === selectedNodeId) {
        found = true;
        break;
      } else {
        if (groups[i].groups && groups[i].groups.length > 0) {
          searchInGroup(groups[i].groups);
          if (found) {
            break;
          }
        }
      }
      trail.pop();
    }
  };
  searchInGroup([rootGroup]);
  return trail;
};

// tslint:disable-next-line: no-shadowed-variable
export const findSelectedGroupNamePath = (
  selectedNodeId: string,
  rootGroup: Group,
) => {
  const trail: string[] = [];
  let found = false;
  const searchInGroup = (groups: any) => {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < groups.length; i++) {
      groups[i] && trail.push(groups[i].name);
      if (groups[i] && groups[i].id === selectedNodeId) {
        found = true;
        break;
      } else {
        if (groups[i] && groups[i].groups && groups[i].groups.length > 0) {
          searchInGroup(groups[i].groups);
          if (found) {
            break;
          }
        }
      }
      trail.pop();
    }
  };
  searchInGroup([rootGroup]);
  return trail;
};

export const treeViewTheme = {
  node: {
    hoverBackground: colors.grey100,
  },
  selected: {
    background: colors.black,
    color: colors.white,
  },
  selectedAncestor: {
    color: colors.black,
    background: colors.grey200,
    hoverBackground: colors.grey100,
  },
  bar: {
    color: colors.grey100,
  },
  indent: {
    hoverBackground: colors.grey100,
  },
  selectedPill: {
    background: colors.white,
    color: colors.black,
  },
};
