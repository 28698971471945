import {
  ReportRowData,
  PeriodAPIData,
  PeriodData,
  ReportRowAPIData,
} from 'components/reports/types';
import moment from 'moment';
import { getLocalizedDuration } from 'utils/trip-utils';
import {
  EntityType,
  GridReportEntityType,
  SubFleetsEntityType,
} from 'components/entity-type/entity-type-utils';
import { metersToMiles, metersToKilometers } from 'atoms/localized-distance';
import {
  atRiskVera2Threshold,
  atRiskVera3Threshold,
} from 'constants/event-thresholds';
import { languageIsJapanese } from './helpers';
import { msToHours, isSignificant } from '../components/reports/utils';
import * as API from '@nauto/api';

export * from '../components/reports/utils';

export const SECTION_NAMES = t => ({
  SAFETY_SCORES: t('Safety Scores'),
  IDENTIFIERS: t('Identifiers'),
  EVENT_RATES_HOURLY: t('Event Rates - Hourly'),
  EVENT_COUNTS_TOTAL: t('Event Counts - Total'),
  VIOLATIONS: t('Violations'),
  ACTIVITY: t('Activity'),
});

export const REPORT_COLUMN_WIDTH = {
  COIN: 58,
  PHOTO: 88,
  NAME: 200,
  DRIVER: 288,
  INFO: 120,
  DATA: 100,
  VEHICLE: 244,
  ICON: 44,
};

export const topPerformerThreshold = 90;
export const crownThreshold = 95;
export const significantImprovementThreshold = 10;
export const significantDeclineThreshold = -10;
export const newThresholdDays = 60;

export const VERA_MIN = 0;
export const VERA_MAX = 100;

const newThreshold = moment().subtract(newThresholdDays, 'd');

export const formattedRate = (rate: number, precision = 1): string => {
  const clippingRate = precision > 0 ? 1 / (10 * precision) : 1;
  if (!rate) {
    return '0';
  }

  if (rate && rate < clippingRate) {
    return `< ${clippingRate}`;
  }

  return rate.toFixed(precision);
};

export const hasDrivingDuration = (data: ReportRowData) => data.movingTime > 0;

/* determine whether a score value is valid */
export const isValidScore = (score: number | undefined) =>
  typeof score === 'number' && score >= 0;

export const getGroupName = (
  data: any,
  entityType?: EntityType | SubFleetsEntityType | GridReportEntityType,
): string => {
  if (isSubFleetsView(entityType)) {
    if (data.entity && data.entity.name) {
      return data.entity.name;
    } else if (data.group) {
      return data.group.name;
    } else {
      return '';
    }
  } else {
    return data.entity
      ? data.entity.group_name ||
          (data.entity.group_names && data.entity.group_names.join(', ')) ||
          (data.entity.group.name && data.entity.group.name.join(', '))
      : '';
  }
};

export const buildInitials = (
  data: any,
  entityType?: EntityType | SubFleetsEntityType | GridReportEntityType,
): string => {
  const groupName =
    typeof data === 'string' ? data : getGroupName(data, entityType);
  const splitLabel = groupName.trim().split(' ');
  const labelHasMultipleWords = splitLabel.length > 1;

  let initials = '';

  // tslint:disable-next-line:prefer-conditional-expression
  if (labelHasMultipleWords) {
    // Pulling the first letter of the first word and the first letter
    // of the last word to account for not grabbing middle names.
    initials = `${splitLabel[0][0]}${splitLabel[splitLabel.length - 1][0]}`;
  } else {
    // Single word, let's grab the first two characters if they're available,
    // otherwise just use the given character.
    initials = groupName.substr(0, groupName.length < 2 ? 1 : 2);
  }

  return initials;
};

export const currentWeekData = (data: ReportRowData): PeriodData =>
  data?.weekly_score?.[data?.weekly_score?.length - 1];

export enum Vera3SubScores {
  HighRisk = 'high_risk_score_delta',
  Inattention = 'inattention_score_delta',
  TrafficViolation = 'traffic_violation_score_delta',
  AggressiveDriving = 'aggressive_driving_score_delta',
  DriverTenure = 'driver_tenure_score',
}

export const getDrivingScore = (
  data: ReportRowData,
  agg: boolean,
  key: Vera3SubScores,
): number => {
  if (agg) {
    return data.agg_score.risk_scores[key];
  }
  const periodData = currentWeekData(data);
  return periodData && periodData.risk_scores[key];
};

export const getPeriodDrivingScore = (
  data: PeriodAPIData,
  key: Vera3SubScores,
): number => data && data.risk_scores[key];

export const getAggAttentiveScore = (data: ReportRowData): number =>
  data.agg_score.risk_scores.attention_score;

export const getAggSmoothDrivingScore = (data: ReportRowData): number =>
  data.agg_score.risk_scores.smooth_driving_score;

export const getMovingTime = (data: ReportRowData): number =>
  data && data.movingTime;

export const getStatisticsMovingDuration = (data: PeriodAPIData): number =>
  data && data.statistics.moving_duration;

export const previousWeekData = (data: ReportRowData): PeriodData =>
  data?.weekly_score?.[data?.weekly_score?.length - 2]; // risk score is vera score

export const getVeraScore = (periodData: PeriodAPIData): number =>
  periodData && periodData.risk_scores.risk_score;

export const getAttentionScore = (periodData: PeriodAPIData): number =>
  periodData && periodData.risk_scores.attention_score;

export const getSmoothDrivingScore = (periodData: PeriodAPIData): number =>
  periodData && periodData.risk_scores.smooth_driving_score;

export const getAggressiveDrivingScore = (periodData: PeriodAPIData): number =>
  periodData && periodData.risk_scores.aggressive_driving_score_delta;

export const getInattentiveDrivingScore = (periodData: PeriodAPIData): number =>
  periodData && periodData.risk_scores.inattention_score_delta;

export const getHighRiskDrivingScore = (periodData: PeriodAPIData): number =>
  periodData && periodData.risk_scores.high_risk_score_delta;

export const getTrafficViolationScore = (periodData: PeriodAPIData): number =>
  periodData && periodData.risk_scores.traffic_violation_score_delta;

export const isAtRisk = (
  periodData: PeriodData,
  atRiskVeraThreshold = languageIsJapanese()
    ? atRiskVera2Threshold
    : atRiskVera3Threshold,
): boolean => getVeraScore(periodData) <= atRiskVeraThreshold;

export const isTopPerformer = (periodData: PeriodData): boolean =>
  getVeraScore(periodData) >= topPerformerThreshold;

export const isCrowned = (periodData: PeriodData): boolean =>
  getVeraScore(periodData) >= crownThreshold;

export const weeklyVeraChange = (
  data: ReportRowData | ReportRowAPIData,
): number => {
  const currentVera = getVeraScore(currentWeekData(data));
  const previousVera = getVeraScore(previousWeekData(data));
  return (
    isValidScore(currentVera) &&
    isValidScore(previousVera) &&
    getVeraScore(currentWeekData(data)) - getVeraScore(previousWeekData(data))
  );
};

export const weeksAtRisk = (
  data: ReportRowData,
  atRiskVeraThreshold = languageIsJapanese()
    ? atRiskVera2Threshold
    : atRiskVera3Threshold,
): number => {
  if (!data) {
    return 0;
  }
  const veraScores = data.weekly_score.map(getVeraScore);
  let index = veraScores.length - 1;
  let weeksAtRisk = 0;
  while (index >= 0) {
    const score = veraScores[index];
    if (score <= atRiskVeraThreshold && isValidScore(score)) {
      weeksAtRisk++;
    } else {
      break;
    }
    index--;
  }

  return weeksAtRisk;
};

export const isSignificantlyImproved = (data: ReportRowData): boolean =>
  weeklyVeraChange(data) > significantImprovementThreshold;

export const isSignificantlyDeclined = (data: ReportRowData): boolean =>
  weeklyVeraChange(data) < significantDeclineThreshold;

export const isNewEntity = (data: ReportRowData): boolean => isNew(data.entity);

export const isNew = (data: { created: string }): boolean =>
  data && data.created ? moment(data.created).isAfter(newThreshold) : false;

export const latestWeeklyScore = (data: ReportRowData): number =>
  getVeraScore(currentWeekData(data));

export const formattedScore = ({
  score,
  showDoubleScore = false,
  showVera3Format = false,
}: {
  score: number;
  showDoubleScore?: boolean;
  showVera3Format?: boolean;
}): string =>
  isValidScore(score)
    ? showVera3Format && score > 0
      ? `-${score}`
      : `${score}`
    : showDoubleScore
    ? '--'
    : '-';

export const compareLatestVera = (
  data1: ReportRowData | API.reports.ReportItem,
  data2: ReportRowData | API.reports.ReportItem,
) =>
  getVeraScore(currentWeekData(data1)) - getVeraScore(currentWeekData(data2));

export const compareLatestHours = (
  data1: ReportRowData,
  data2: ReportRowData,
) => getMovingTime(data1) - getMovingTime(data2);

export const hasHourDriven = (
  data: ReportRowAPIData | API.reports.ReportItem,
) => msToHours(getStatisticsMovingDuration(currentWeekData(data))) > 1;

export const compareLatestMovingDuration = (
  data1: ReportRowAPIData | API.reports.ReportItem,
  data2: ReportRowAPIData | API.reports.ReportItem,
) =>
  getStatisticsMovingDuration(currentWeekData(data1)) -
  getStatisticsMovingDuration(currentWeekData(data2));

export const formattedEventRate = (score: number, precision = 1): number => {
  return parseFloat((Math.round(score * 100) / 100).toFixed(precision));
};

export const eventCellAccessor = (
  data: ReportRowData,
  eventType: string,
  key: string,
  isScoreVersionVera3?: boolean,
) =>
  isSignificant(data, isScoreVersionVera3) ? data.events[eventType][key] : -1;

export const formattedEventCell = (value: number): string =>
  value >= 0 ? `${value}` : '-';

export const eventCellExport = (
  data: ReportRowData,
  eventType: string,
  key: string,
) => {
  return formattedRate(data.events[eventType][key]);
};

export const durationExport = (
  data: ReportRowData,
  key: string,
  t: any,
): string => {
  return data[key]
    ? getLocalizedDuration({ t, duration: data[key], unit: 'hour' })
    : t('none');
};

export const hasDuration = (data: ReportRowData, rateType: string): boolean =>
  !!data[rateType];

export const isDriverView = (
  activeEntityType: EntityType | SubFleetsEntityType,
): boolean => {
  return activeEntityType === EntityType.DRIVER;
};

export const isVehiclesView = (
  activeEntityType: EntityType | SubFleetsEntityType,
): boolean => {
  return activeEntityType === EntityType.VEHICLE;
};

export const isSubFleetsView = (
  activeEntityType: EntityType | SubFleetsEntityType | GridReportEntityType,
): boolean => {
  return (
    activeEntityType === SubFleetsEntityType.SUBFLEETS ||
    activeEntityType === GridReportEntityType.Subfleets
  );
};

export const rateCellExport = (data: ReportRowData, rateType: string) =>
  formattedRate(data[rateType]);

export const rateCellAccessor = (data: ReportRowData, rateType: string) =>
  data[rateType];
export const distanceMiles = (periodData: PeriodData): number =>
  periodData && periodData.statistics && periodData.statistics.trip_distance;

export const hasTripStatisticsDuration = (
  row: ReportRowData,
  type: string,
): boolean =>
  Boolean(
    row &&
      row.agg_score &&
      row.agg_score.statistics &&
      row.agg_score.statistics[type] > 0,
  );
export const getTripStatisticsDuration = (
  row: ReportRowData,
  type: string,
): number =>
  hasTripStatisticsDuration(row, type) ? row.agg_score.statistics[type] : 0;

export const getMovingDuration = (row: ReportRowData): number =>
  getTripStatisticsDuration(row, 'moving_duration');

export const getPostedSpeedDuration = (
  row: ReportRowData,
  isScoreVersionVera3 = false,
): number =>
  isScoreVersionVera3
    ? getEventTypeDuration(row, 'posted-speeding')
    : getTripStatisticsDuration(row, 'posted_speeding_duration');

export const getNoSeatBeltDuration = (
  row: ReportRowData,
  isScoreVersionVera3 = false,
): number =>
  isScoreVersionVera3
    ? getEventTypeDuration(row, 'no-seat-belt')
    : getTripStatisticsDuration(row, 'no_seat_belt_duration');

export const getMaxSpeedDuration = (
  row: ReportRowData,
  isScoreVersionVera3 = false,
): number =>
  isScoreVersionVera3
    ? getTripStatisticsDuration(row, 'max_speeding_duration')
    : getTripStatisticsDuration(row, 'speeding_duration');

export const calculateMaxSpeedRate = (
  row: ReportRowData,
  isScoreVersionVera3 = false,
): number =>
  row.movingTime &&
  getMaxSpeedDuration(row, isScoreVersionVera3) &&
  (getMaxSpeedDuration(row, isScoreVersionVera3) / getMovingDuration(row)) *
    100;

export const distanceMillionMeters = (periodData: PeriodData) =>
  distanceMiles(periodData) / 1000000;

export const distanceMillion = (
  periodData: PeriodData,
  useMiles: boolean,
): number =>
  useMiles
    ? metersToMiles(distanceMillionMeters(periodData))
    : metersToKilometers(distanceMillionMeters(periodData));

export const hasTotal = (row: ReportRowData, eventType: string): boolean =>
  Boolean(
    row &&
      row.agg_score &&
      row.agg_score.event_counts &&
      row.agg_score.event_counts[eventType] &&
      typeof row.agg_score.event_counts[eventType].total === 'number',
  );

export const getTotal = (row: ReportRowData, eventType: string): number =>
  hasTotal(row, eventType) ? row.agg_score.event_counts[eventType].total : 0;

export const hasEventTypeDuration = (
  row: ReportRowData,
  eventType: string,
): boolean =>
  Boolean(
    row &&
      row.agg_score &&
      row.agg_score.event_counts &&
      row.agg_score.event_counts[eventType] &&
      typeof row.agg_score.event_counts[eventType].duration === 'number',
  );

export const getEventTypeDuration = (
  row: ReportRowData,
  eventType: string,
): number =>
  hasEventTypeDuration(row, eventType)
    ? row.agg_score.event_counts[eventType].duration
    : 0;

export const eventsPerMillion = (
  periodData: PeriodData,
  event: string,
  useMiles: boolean,
): number =>
  periodData &&
  periodData.events &&
  periodData.events[event] &&
  distanceMillion(periodData, useMiles)
    ? periodData.events[event].count / distanceMillion(periodData, useMiles)
    : 0;

export const convertToObjByEntity = <T>(
  data: T[],
  type: EntityType,
): Record<string, T> => {
  if (!data || !data.length) {
    return null;
  }

  const IDKey: 'driver_id' | 'vehicle_id' = `${type}_id`;
  return data.reduce((obj, current) => {
    obj[current[IDKey]] = current;
    return obj;
  }, {});
};

export const getRate = (rows, type) => {
  let sum = 0;
  let count = 0;

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    const c = Number(row[type]);
    if (c !== Infinity) {
      sum += c;
      count += 1;
    }
  }

  return (sum / count).toFixed(1);
};

export const getVeraSubScore = (
  row: ReportRowData,
  type: Vera3SubScores,
): number => {
  const score = row.agg_score.risk_scores[type];
  return score > 0 ? -score : 0;
};
