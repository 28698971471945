import { PACKAGE, FEATURES } from './constants';
import {
  EntityType,
  SubFleetsEntityType,
} from 'components/entity-type/entity-type-utils';
import { ROUTES } from 'routes/constants';

const SELECTIVE_ROUTE_ENABLED = 'selective-route-enabled';

const allowedTypesInPackage = {
  [PACKAGE.PLATFORM_3_BUNDLE]: [
    EntityType.DRIVER,
    EntityType.VEHICLE,
    SubFleetsEntityType.SUBFLEETS,
  ],
  [PACKAGE.MANAGER_LED_COACHING]: [
    EntityType.DRIVER,
    EntityType.VEHICLE,
    SubFleetsEntityType.SUBFLEETS,
  ],
  [PACKAGE.DRIVER_BEHAVIOR_ALERTS]: [
    EntityType.VEHICLE,
    SubFleetsEntityType.SUBFLEETS,
  ],
  [PACKAGE.PREVENT_PRO]: [EntityType.VEHICLE, SubFleetsEntityType.SUBFLEETS],
  [PACKAGE.PREVENT_ENTERPRISE]: [
    EntityType.DRIVER,
    EntityType.VEHICLE,
    SubFleetsEntityType.SUBFLEETS,
  ],
  [PACKAGE.NAUTO_PREMIUM]: [
    EntityType.DRIVER,
    EntityType.VEHICLE,
    SubFleetsEntityType.SUBFLEETS,
  ],
  [PACKAGE.COACH_LEGACY]: [
    EntityType.DRIVER,
    EntityType.VEHICLE,
    SubFleetsEntityType.SUBFLEETS,
  ],
  [PACKAGE.PREVENT_LEGACY]: [
    EntityType.DRIVER,
    EntityType.VEHICLE,
    SubFleetsEntityType.SUBFLEETS,
  ],
  [PACKAGE.ESSENTIAL]: [EntityType.VEHICLE, SubFleetsEntityType.SUBFLEETS],
};

const switchEntities = {
  [PACKAGE.PREVENT_PRO]: false,
  [PACKAGE.PREVENT_ENTERPRISE]: true,
  [PACKAGE.COACH_LEGACY]: true,
  [PACKAGE.PREVENT_LEGACY]: true,
  [PACKAGE.PLATFORM_3_BUNDLE]: true,
  [PACKAGE.MANAGER_LED_COACHING]: true,
  [PACKAGE.DRIVER_BEHAVIOR_ALERTS]: false,
  [PACKAGE.NAUTO_PREMIUM]: true,
};

const selectEntities = {
  [PACKAGE.PREVENT_PRO]: {
    [SELECTIVE_ROUTE_ENABLED]: true,
    [ROUTES.ENTITY_EVENTS]: true,
  },
  [PACKAGE.PREVENT_ENTERPRISE]: {
    SELECTIVE_ROUTE_ENABLED: false,
  },
  [PACKAGE.COACH_LEGACY]: {
    SELECTIVE_ROUTE_ENABLED: false,
  },
  [PACKAGE.PREVENT_LEGACY]: {
    SELECTIVE_ROUTE_ENABLED: false,
  },
  [PACKAGE.NAUTO_PREMIUM]: {
    SELECTIVE_ROUTE_ENABLED: false,
  },
};

const showEventTypeFilters = {
  [PACKAGE.PREVENT_PRO]: false,
  [PACKAGE.PREVENT_ENTERPRISE]: true,
  [PACKAGE.COACH_LEGACY]: true,
  [PACKAGE.PREVENT_LEGACY]: true,
  [PACKAGE.PLATFORM_3_BUNDLE]: true,
  [PACKAGE.MANAGER_LED_COACHING]: true,
  [PACKAGE.DRIVER_BEHAVIOR_ALERTS]: false,
  [PACKAGE.NAUTO_PREMIUM]: true,
};

export const packageFeatures = (plan: PACKAGE, features?: any) => ({
  packageName: () => plan,
  isTypeAllowed: (entity: EntityType | SubFleetsEntityType) =>
    allowedTypesInPackage[plan].includes(entity),
  defaultEntityType: () =>
    allowedTypesInPackage[plan] && allowedTypesInPackage[plan][0],
  canSwitchEntities: () => switchEntities[plan],
  canAccessEvent: eventType =>
    features && features[FEATURES.EVENT_TYPES].includes(eventType),
  canSelectEntities: (route?: ROUTES) => {
    return selectEntities[plan][SELECTIVE_ROUTE_ENABLED]
      ? !!selectEntities[plan][route]
      : true;
  },
  showTypeFilters: () => showEventTypeFilters[plan],
});

export const getPackageName = (plan: PACKAGE): string => {
  switch (plan) {
    case PACKAGE.COACH_LEGACY:
      return 'Coach Legacy';
    case PACKAGE.PREVENT_LEGACY:
      return 'Prevent Legacy';
    case PACKAGE.PREVENT_ENTERPRISE:
      return 'Enterprise';
    case PACKAGE.PREVENT_PRO:
      return 'Pro';
    case PACKAGE.PLATFORM_3_BUNDLE:
      return 'Platform 3 Bundle';
    case PACKAGE.MANAGER_LED_COACHING:
      return 'Manager Led Coaching';
    case PACKAGE.DRIVER_BEHAVIOR_ALERTS:
      return 'Driver Behavior Alerts';
    case PACKAGE.NAUTO_PREMIUM:
      return 'Nauto Premium';
    case PACKAGE.ESSENTIAL:
      return 'Nauto Essential';
  }
};

export const getFeatureName = (feature, t) => {
  switch (feature) {
    case FEATURES.DRIVER_INSIGHTS:
      return t('Driver insights');
    case FEATURES.VEHICLE_INSIGHTS:
      return t('Vehicle insights');
    case FEATURES.TRIPS:
      return t('Trips');
    case FEATURES.REPORTS:
      return t('Trips');
    case FEATURES.EVENTS:
      return t('Events');
    case FEATURES.VEHICLE_MANAGEMENT:
      return t('Vehicle management');
    case FEATURES.DRIVER_MANAGEMENT:
      return t('Driver management');
    case FEATURES.DRIVER_COACHING:
      return t('Driver coaching');
    case FEATURES.SEVERE_EVENTS_ALERTS:
      return t('Severe events alerts');
    case FEATURES.EVENT_TYPES:
      return t('Event types');
    case FEATURES.DRIVER_APP:
      return t('Driver app');
    case FEATURES.AUDIO_RECORDING:
      return t('Audio recording');
    case FEATURES.BLURRING:
      return t('Blurring');
    case FEATURES.PANIC_NOTIFICATIONS:
      return t('Panic notifications');
    case FEATURES.CVR_NOTIFICATIONS:
      return t('CVR notifications');
    case FEATURES.DEVICE_HEALTH:
      return t('Device health');
    case FEATURES.CREATED_ON:
      return t('Created on');
    case FEATURES.DISABLE_INTERIOR_VIDEO:
      return t('Disable interior video');
    case FEATURES.ON_DEMAND_VIDEO_ALACARTE:
      return t('Request video');
    case FEATURES.ON_DEMAND_VIDEO_REQUEST_SUB:
      return t('Request video');
    case FEATURES.PCA_REAR_END_COLLISION:
      return t('Predictive collision alerts');
    case FEATURES.SELF_GUIDED_COACHING:
      return t('Self guided coaching');
    case FEATURES.MARK_BUTTON:
      return t('Mark button');
    case FEATURES.DRIVER_INITIATED_VIDEO:
      return t('Mark button');
    case FEATURES.INCIDENT_REPORTING:
      return t('Incident reporting');
    default:
      return null;
  }
};
