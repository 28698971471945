import { presetRanges } from 'components/reports/date-filter-utils';
import { DurationType } from 'components/reports/types';
import moment, { Moment } from 'moment-timezone';
import { languageIsJapanese } from './helpers';

export interface Range {
  min: Moment;
  max: Moment;
}

export const graphedDays = 6;
export const graphedWeeks = 6;
// TODO: Remove this and use 12 months whenever we move toward 12 months range
export const graphedSixMonths = 6;
export const graphedMonths = 12;

const endOfLatestScoredWeek = () =>
  moment()
    .tz('utc')
    .startOf('w');

export const startOfLatestScoredISOWeek = (): Moment =>
  moment()
    .tz('utc')
    .startOf('isoWeek');

const endOfLastScoredISOWeek = () =>
  startOfLatestScoredISOWeek()
    .subtract(1, 'd')
    .endOf('d');

const lastCompletedDay = () =>
  moment()
    .subtract(1, 'd')
    .startOf('d');

const endOflastCompletedDay = () =>
  moment()
    .tz('utc')
    .startOf('d')
    .subtract(1, 'd')
    .endOf('d');

// end of last month === start of this month
export const endOflastCompletedMonth = (): Moment =>
  moment()
    .tz('utc')
    .startOf('month');

/**
 * Using dateRange.lastScoredWeek(), the reporting query
 * was not able to pick up last weeks reporting bucket
 * - after further QA/Testing we should replace dateRange.lastScoredWeek()
 */
export const lastScoredWeekNew = {
  max: endOfLatestScoredWeek(),
  min: endOfLatestScoredWeek().subtract(1, 'w'),
};

// todo: @onkarg consolidate dateRange and isoDateRange
export const dateRange = {
  lastScoredDay: (): Range => ({
    max: moment().tz('utc'),
    min: moment().tz('utc'),
  }),
  lastScoredMonth: (): Range => ({
    max: moment()
      .tz('utc')
      .endOf('month'),
    min: moment()
      .tz('utc')
      .startOf('month'),
  }),
  lastScoredYear: (): Range => ({
    max: moment()
      .tz('utc')
      .endOf('year'),
    min: moment()
      .tz('utc')
      .startOf('year'),
  }),
  lastScoredQuarter: (): Range => ({
    max: moment()
      .tz('utc')
      .quarter(moment().quarter())
      .endOf('quarter'),
    min: moment()
      .tz('utc')
      .quarter(moment().quarter())
      .startOf('quarter'),
  }),
  gridLastScoredWeek: (): Range => ({
    max: moment()
      .endOf('isoWeek')
      .subtract(1, 'week'),
    min: moment()
      .startOf('isoWeek')
      .subtract(1, 'week'),
  }),
  lastScoredWeek: (): Range => ({
    max: moment()
      .tz('utc')
      .endOf('w')
      .subtract(1, 'week'),
    min: moment()
      .tz('utc')
      .startOf('w')
      .subtract(1, 'week'),
  }),
  last30CompleteDays: (): Range => ({
    max: lastCompletedDay().endOf('d'),
    min: lastCompletedDay().subtract(30, 'd'),
  }),
  secondToLast30CompleteDays: (): Range => ({
    max: lastCompletedDay()
      .subtract(31, 'd')
      .endOf('d'),
    min: lastCompletedDay().subtract(61, 'd'),
  }),
  lastTwoScoredWeeks: (): Range => ({
    max: endOfLatestScoredWeek(),
    min: endOfLatestScoredWeek().subtract(2, 'w'),
  }),
  trendGraphWeeks: (): Range => ({
    max: moment().startOf('isoWeek'),
    min: moment()
      .startOf('isoWeek')
      .subtract(graphedWeeks, 'w'),
  }),
  expandedGraphMonths: (): Range => ({
    max: moment().startOf('month'),
    min: endOfLatestScoredWeek()
      .subtract(graphedMonths, 'months')
      .startOf('month'),
  }),
  trendGraphMonths: (): Range => ({
    max: endOflastCompletedMonth(),
    min: endOflastCompletedMonth()
      .subtract(graphedMonths, 'months')
      .startOf('month'),
  }),
  trendGraphDays: (): Range => ({
    max: endOflastCompletedDay(),
    min: endOflastCompletedDay()
      .subtract(graphedDays, 'd')
      .startOf('d'),
  }),
};

export const isoDateRange = {
  lastScoredWeek: (): Range => ({
    min: startOfLatestScoredISOWeek().subtract(1, 'week'),
    max: endOfLastScoredISOWeek(),
  }),
  lastTwoScoredWeeks: (): Range => ({
    min: startOfLatestScoredISOWeek().subtract(2, 'w'),
    max: endOfLastScoredISOWeek(),
  }),
  trendGraphWeeks: (): Range => ({
    min: startOfLatestScoredISOWeek().subtract(graphedWeeks, 'w'),
    max: endOfLastScoredISOWeek(),
  }),
  trendLineGraphMonths: (): Range => ({
    max: moment()
      .tz('utc')
      .endOf('month')
      .subtract(1, 'month'),
    min: endOflastCompletedMonth()
      .subtract(graphedSixMonths, 'months')
      .startOf('month'),
  }),
};

export const formattedRange = (range: Range): string => {
  if (range) {
    if (!languageIsJapanese()) {
      if (range.min.isSame(range.max, 'day')) {
        return range.min.utc().format('MMMM Do, YYYY');
      }
      if (range.min.isSame(range.max, 'month')) {
        return `${range.min.utc().format('MMMM Do')} - ${range.max
          .utc()
          .format('Do, YYYY')}`;
      }
      if (range.min.isSame(range.max, 'year')) {
        return `${range.min.utc().format('MMMM Do')} - ${range.max
          .utc()
          .format('MMMM Do, YYYY')}`;
      }
    }

    return `${range.min && range.min.utc().format('LL')} - ${range.max &&
      range.max.utc().format('LL')}`;
  }
  return '';
};

const JOINER = '_';

export const stringifyRange = (range: Range): string => {
  return [
    range.min.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
    range.max.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
  ].join(JOINER);
};

export const parseRange = (rangeParam: string): Range => {
  const parts = rangeParam && rangeParam.split(JOINER);
  if (parts.length !== 2) {
    return;
  }
  const [minString, maxString] = parts;
  const min = moment(minString).utc();
  const max = moment(maxString).utc();
  if (min.isValid() && max.isValid()) {
    return {
      min,
      max,
    };
  }
};

// strip away timezone and read it as a UTC datetime, regardless of actual timestamp
export const toUTCDay = (date: Moment): Moment => {
  return moment.utc(date.format('YYYY-MM-DDT00:00:00')).tz('UTC');
};

export const toEndOfDayUTCRange = (
  min: Moment,
  max: Moment,
): { min: Moment; max: Moment } => {
  return {
    min: toUTCDay(min),
    max: toUTCDay(max).endOf('d'),
  };
};

const UNTAGGED_FACES_DAYS = 35;

export const untaggedFacesCutoff = (): Moment =>
  moment()
    .subtract(UNTAGGED_FACES_DAYS, 'd')
    .startOf('d');

export const getRangePresetType = (min: Moment, max: Moment): DurationType => {
  if (isYearlyRange(min, max) || isMonthlyRange(min, max)) {
    return DurationType.Monthly;
  }

  if (isISOWeeklyRange(min, max)) {
    return DurationType.Weekly;
  }

  return DurationType.Daily;
};

export const isMonthlyRange = (min: Moment, max: Moment): boolean => {
  const startOfMonth = min.clone().startOf('month');
  const endOfMonth = max.clone().endOf('month');
  return (
    min.isSame(max, 'month') &&
    min.isSame(startOfMonth, 'day') &&
    max.isSame(endOfMonth, 'day')
  );
};

export const isISOWeeklyRange = (min: Moment, max: Moment): boolean => {
  const startOfWeek = min.clone().startOf('isoWeek');
  const endOfWeek = max.clone().endOf('isoWeek');

  return (
    min.isSame(max, 'isoWeek') &&
    min.isSame(startOfWeek, 'day') &&
    max.isSame(endOfWeek, 'day')
  );
};

export const isYearlyRange = (min: Moment, max: Moment): boolean => {
  const startOfYear = min.clone().startOf('year');
  const endOfYear = max.clone().endOf('year');
  return (
    min.isSame(max, 'year') &&
    min.isSame(startOfYear, 'day') &&
    max.isSame(endOfYear, 'day')
  );
};
