import { AllowedEvent } from 'components/events/event-types';
import each from 'lodash/each';

export const LABELS = {
  SEVERITY: 'severity',
  UNIFIED_COACHABLE_DISTRACTION: 'unified-coachable-distraction',
  COACHING_STATUS: 'coaching-status',
  COACHING_SESSION: 'coaching-session',
  CLIENT_EVENT_TYPE: 'client-event-type',
  CUSTOMER_REVIEW_FALSE_POSITIVE: 'customer-review-false-positive',
  DRIVER_VIEWED_EVENT: 'driver-viewed-event',
};

// 10/20/20 - currently have pending, true, false values
// product plans to add more in future
export const FALSE_POSITIVE_VALUES = {
  UNDO: 'undo',
  PENDING: 'pending',
  TRUE: 'true',
  FALSE: 'false',
};

enum EventType {
  AccelerationHard = 'acceleration-hard',
  BrakingHard = 'braking-hard',
  BrakingSevere = 'braking-severe',
  BrakingHardSevere = 'braking-hard-severe',
  CornerLeftHard = 'corner-left-hard',
  CornerRightHard = 'corner-right-hard',
  Distraction = 'distraction',
  ReviewConfirmedDistraction = 'review-confirmed-distraction',
  Tailgating = 'tailgating',
  ReviewConfirmedTailgating = 'review-confirmed-tailgating',
  Mark = 'mark',
  MarkPanic = 'mark-panic',
  CustomVideoRequest = 'custom-video-request',
  SevereGEvent = 'severe-g-event',
  ReviewUnsureSevereGEvent = 'review-unsure-severe-g-event',
  ReviewConfirmedSevereGEvent = 'review-confirmed-severe-g-event',
  Collision = 'collision',
  NearCollision = 'near-collision',
  Speeding = 'speeding',
  Smoking = 'smoking',
  CellPhone = 'cell-phone',
  NoSeatBelt = 'no-seat-belt',
  Drowsiness = 'drowsiness',
  ManeuverBackUp = 'maneuver-back-up',
  PostedSpeeding = 'posted-speeding',
  SafetyIncident = 'unified-coachable-safety-incident',
}

// the raw event types that are retrieved via APIs
export const TYPE = {
  ACCEL: EventType.AccelerationHard,
  BRAKING: EventType.BrakingHard,
  BRAKING_SEVERE: EventType.BrakingSevere,
  BRAKING_HARD_SEVERE: EventType.BrakingHardSevere,
  CORNERING_LEFT: EventType.CornerLeftHard,
  CORNERING_RIGHT: EventType.CornerRightHard,
  DISTRACTION: EventType.Distraction,
  CONFIRMED_DISTRACTION: EventType.ReviewConfirmedDistraction,
  TAILGATING: EventType.Tailgating,
  CONFIRMED_TAILGATING: EventType.ReviewConfirmedTailgating,
  MARK: EventType.Mark,
  MARK_PANIC: EventType.MarkPanic,
  REQUEST: EventType.CustomVideoRequest,
  POSSIBLE_COLLISION: EventType.SevereGEvent,
  UNSURE_COLLISION: EventType.ReviewUnsureSevereGEvent,
  CONFIRMED_COLLISION_VERA2: EventType.ReviewConfirmedSevereGEvent,
  CONFIRMED_COLLISION_VERA3: EventType.Collision,
  NEAR_COLLISION: EventType.NearCollision,
  SPEEDING: EventType.Speeding,
  SMOKING: EventType.Smoking,
  CELL_PHONE: EventType.CellPhone,
  SEATBELT: EventType.NoSeatBelt,
  DROWSINESS: EventType.Drowsiness,
  BACKING_UP: EventType.ManeuverBackUp,
  POSTED_SPEEDING: EventType.PostedSpeeding,
  SAFETY_INCIDENT: EventType.SafetyIncident,
};

export enum Filter {
  Acceleration = 'acceleration',
  Braking = 'braking',
  Cornering = 'cornering',
  Distraction = 'distraction',
  Tailgating = 'tailgating',
  Mark = 'mark',
  Request = 'request',
  Collision = 'collision',
  NearCollision = 'near-collision',
  Speeding = 'speeding',
  Smoking = 'smoking',
  CellPhone = 'cell-phone',
  NoSeatBelt = 'no-seat-belt',
  ForwardCollisionWarning = 'forward-collision-warning',
  PedestrianCollisionWarning = 'pedestrian-collision-warning',
  Drowsiness = 'drowsiness',
  BackingUp = 'backing-up',
  HitAndRun = 'hit-and-run',
  RoadRage = 'road-rage',
  StopSign = 'stop-sign',
  RollingStop = 'rolling-stop',
  IntersectionViolationWarning = 'intersection-violation-warning',
  RepeatedEvents = 'repeated-events',
  SafetyIncident = 'unified-coachable-safety-incident',
}

// the names of filters that we want to count or group events for on the driver insights page
export const FILTER = {
  ACCELERATION: Filter.Acceleration,
  BRAKING: Filter.Braking,
  CORNERING: Filter.Cornering,
  DISTRACTION: Filter.Distraction,
  TAILGATING: Filter.Tailgating,
  MARKED: Filter.Mark,
  REQUESTED: Filter.Request,
  COLLISION: Filter.Collision,
  NEAR_COLLISION: Filter.NearCollision,
  SPEEDING: Filter.Speeding,
  SMOKING: Filter.Smoking,
  CELL_PHONE: Filter.CellPhone,
  SEATBELT: Filter.NoSeatBelt,
  FORWARD_COLLISION_WARNING: Filter.ForwardCollisionWarning,
  PEDESTRIAN_COLLISION_WARNING: Filter.PedestrianCollisionWarning,
  DROWSINESS: Filter.Drowsiness,
  BACKING_UP: Filter.BackingUp,
  HIT_AND_RUN: Filter.HitAndRun,
  ROAD_RAGE: Filter.RoadRage,
  STOP_SIGN: Filter.StopSign,
  ROLLING_STOP: Filter.RollingStop,
  INTERSECTION_VIOLATION_WARNING: Filter.IntersectionViolationWarning,
  REPEATED_EVENTS: Filter.RepeatedEvents,
  SAFETY_INCIDENT: Filter.SafetyIncident,
};

export const COACHING_LABELS = {
  COACHABLE: 'coachable',
  COACHED: 'coached',
  UNCOACHABLE: 'uncoachable',
};

export const EVENT_TYPES: string[] = Object.keys(TYPE).map(k => TYPE[k]);
export const EVENT_FILTERS: string[] = Object.keys(FILTER).map(k => FILTER[k]);

export const DEFAULT_TYPE_FILTER = [
  FILTER.SAFETY_INCIDENT,
  FILTER.ACCELERATION,
  FILTER.BRAKING,
  FILTER.CORNERING,
  FILTER.DISTRACTION,
  FILTER.TAILGATING,
  FILTER.MARKED,
  FILTER.REQUESTED,
  FILTER.COLLISION,
  FILTER.NEAR_COLLISION,
  FILTER.FORWARD_COLLISION_WARNING,
  FILTER.DROWSINESS,
  FILTER.SPEEDING,
];

export const EXCLUDE_EVENT_FILTERS_JP = {
  [FILTER.FORWARD_COLLISION_WARNING]: 'forwardCollisionWarning',
  [FILTER.SPEEDING]: 'postedSpeedLimit',
  [FILTER.DROWSINESS]: 'drowsiness',
};

// all event types except snapshots
export const FEED_EVENT_TYPES: string[] = EVENT_TYPES.filter(
  type => type !== TYPE.POSSIBLE_COLLISION,
);

const excludeEventsInTrips = [
  TYPE.POSSIBLE_COLLISION,
  TYPE.SPEEDING,
  TYPE.SMOKING,
  TYPE.CELL_PHONE,
  TYPE.SEATBELT,
];
export const TRIP_EVENT_TYPES: string[] = EVENT_TYPES.filter(
  type => !excludeEventsInTrips.includes(type),
);

export const RISK_EVENT_FILTERS = [
  FILTER.ACCELERATION,
  FILTER.BRAKING,
  FILTER.CORNERING,
  FILTER.DISTRACTION,
  FILTER.TAILGATING,
  FILTER.SAFETY_INCIDENT,
];

export const VIOLATION_EVENT_FILTERS = [
  FILTER.SMOKING,
  FILTER.CELL_PHONE,
  FILTER.SEATBELT,
];

// the event types for which each filter applies
export const TYPES_FOR_FILTER: { [key: string]: string[] } = {
  [FILTER.ACCELERATION]: [TYPE.ACCEL],
  [FILTER.BRAKING]: [
    TYPE.BRAKING,
    TYPE.BRAKING_SEVERE,
    TYPE.BRAKING_HARD_SEVERE,
  ],
  [FILTER.CORNERING]: [TYPE.CORNERING_LEFT, TYPE.CORNERING_RIGHT],
  [FILTER.DISTRACTION]: [TYPE.DISTRACTION, TYPE.CONFIRMED_DISTRACTION],
  [FILTER.TAILGATING]: [TYPE.TAILGATING, TYPE.CONFIRMED_TAILGATING],
  // replace above two lines with these to include "unsure" reviewed events in display
  // [FILTER.DISTRACTION]: [TYPE.DISTRACTION, TYPE.UNSURE_DISTRACTION, TYPE.CONFIRMED_DISTRACTION],
  // [FILTER.TAILGATING]: [TYPE.TAILGATING, TYPE.UNSURE_TAILGATING, TYPE.CONFIRMED_TAILGATING],
  [FILTER.MARKED]: [TYPE.MARK, TYPE.MARK_PANIC],
  [FILTER.REQUESTED]: [TYPE.REQUEST],
  [FILTER.COLLISION]: [TYPE.CONFIRMED_COLLISION_VERA2],
  [FILTER.NEAR_COLLISION]: [TYPE.NEAR_COLLISION],
  [FILTER.SPEEDING]: [TYPE.SPEEDING, TYPE.POSTED_SPEEDING],
  [FILTER.SMOKING]: [TYPE.SMOKING],
  [FILTER.CELL_PHONE]: [TYPE.CELL_PHONE],
  [FILTER.SEATBELT]: [TYPE.SEATBELT],
  [FILTER.SAFETY_INCIDENT]: [TYPE.SAFETY_INCIDENT],
};

// the groups (dropdowns) of the filters
export const FILTER_DROPDOWN = {
  REQUESTS: 'requests',
  RISKS: 'risks',
  COLLISIONS: 'collisions',
  NEAR_COLLISIONS: 'near-collisions',
  VIOLATIONS: 'violations',
  HIGH_RISK: 'high-risk',
  INATTENTION: 'inattention',
  TRAFFIC_VIOLATION: 'traffic-violation',
  AGGRESSIVE_DRIVING: 'aggressive-driving',
  NON_COMPLIANCE: 'non-compliance',
};

// the headers for sections of the filters
export const typeFilterDropdowns = [
  {
    title: FILTER_DROPDOWN.REQUESTS,
    typeGroups: [FILTER.MARKED, FILTER.REQUESTED],
    hideDropdown: false,
  },
  {
    title: FILTER_DROPDOWN.RISKS,
    typeGroups: RISK_EVENT_FILTERS,
    hideDropdown: false,
  },
  {
    title: FILTER_DROPDOWN.COLLISIONS,
    typeGroups: [FILTER.COLLISION],
    hideDropdown: true,
  },
  {
    title: FILTER_DROPDOWN.NEAR_COLLISIONS,
    typeGroups: [FILTER.NEAR_COLLISION],
    hideDropdown: true,
  },
];

const buildFilterForType = () => {
  const filterForType = {};
  each(TYPES_FOR_FILTER, (types, filter) => {
    each(types, type => {
      filterForType[type] = filter;
    });
  });
  return filterForType;
};

export const FILTER_FOR_TYPE = buildFilterForType();

export const shownTypesFromFilters = (filterNames: string[]) => {
  let shownTypes = [];
  filterNames.forEach(filterName => {
    shownTypes = shownTypes.concat(TYPES_FOR_FILTER[filterName]);
  });
  return shownTypes;
};

export const SEVERITIES = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

type EventFlags =
  | 'nearCollisionEvents'
  | 'seatbeltViolations'
  | 'forwardCollisionWarning'
  | 'drowsiness'
  | 'postedSpeedLimit'
  | 'backingUpViolation'
  | 'pedestrianCollisionWarning'
  | 'roadRage'
  | 'hitAndRun'
  | 'postFactoStopSign'
  | 'postFactoStopSign'
  | 'intersectionViolationWarning'
  | 'safetyIncidentEvent';

export type FilterMapping<T> = {
  [key in Filter]?: T;
};

export type FlaggedFilterMap = FilterMapping<EventFlags[]>;

export const FLAGGED_FILTER_MAP: FlaggedFilterMap = {
  [FILTER.NEAR_COLLISION]: ['nearCollisionEvents'],
  [FILTER.SEATBELT]: ['seatbeltViolations'],
  [FILTER.FORWARD_COLLISION_WARNING]: ['forwardCollisionWarning'],
  [FILTER.DROWSINESS]: ['drowsiness'],
  [FILTER.SPEEDING]: ['postedSpeedLimit'],
  [FILTER.BACKING_UP]: ['backingUpViolation'],
  [FILTER.PEDESTRIAN_COLLISION_WARNING]: [
    'pedestrianCollisionWarning',
    'nearCollisionEvents',
  ],
  [FILTER.ROAD_RAGE]: ['roadRage'],
  [FILTER.HIT_AND_RUN]: ['hitAndRun'],
  [FILTER.STOP_SIGN]: ['postFactoStopSign'],
  [FILTER.ROLLING_STOP]: ['postFactoStopSign'],
  [FILTER.INTERSECTION_VIOLATION_WARNING]: ['intersectionViolationWarning'],
  [FILTER.SAFETY_INCIDENT]: ['safetyIncidentEvent'],
};

export type AllowedFilterMap = FilterMapping<AllowedEvent>;

export const ALLOWED_FILTER_MAP: AllowedFilterMap = {
  [FILTER.MARKED]: AllowedEvent.Request,
  [FILTER.REQUESTED]: AllowedEvent.Request,
  [FILTER.ACCELERATION]: AllowedEvent.Acceleration,
  [FILTER.BRAKING]: AllowedEvent.Braking,
  [FILTER.CORNERING]: AllowedEvent.Cornering,
  [FILTER.DISTRACTION]: AllowedEvent.Distraction,
  [FILTER.TAILGATING]: AllowedEvent.Tailgating,
  [FILTER.COLLISION]: AllowedEvent.Collision,
  [FILTER.NEAR_COLLISION]: AllowedEvent.NearCollision,
  [FILTER.SMOKING]: AllowedEvent.Smoking,
  [FILTER.CELL_PHONE]: AllowedEvent.CellPhone,
  [FILTER.SEATBELT]: AllowedEvent.NoSeatBelt,
  [FILTER.FORWARD_COLLISION_WARNING]: AllowedEvent.ForwardCollisionWarning,
  [FILTER.DROWSINESS]: AllowedEvent.Drowsiness,
  [FILTER.SPEEDING]: AllowedEvent.PostedSpeeding,
};

export type DefaultFilters = FilterMapping<boolean>;

export const DEFAULT_EVENT_FILTERS: DefaultFilters = {
  [FILTER.NEAR_COLLISION]: false,
  [FILTER.SEATBELT]: false,
  [FILTER.FORWARD_COLLISION_WARNING]: false,
  [FILTER.DROWSINESS]: false,
  [FILTER.SPEEDING]: false,
  [FILTER.BACKING_UP]: false,
  [FILTER.PEDESTRIAN_COLLISION_WARNING]: false,
  [FILTER.ROAD_RAGE]: false,
  [FILTER.HIT_AND_RUN]: false,
  [FILTER.STOP_SIGN]: false,
  [FILTER.ROLLING_STOP]: false,
  [FILTER.INTERSECTION_VIOLATION_WARNING]: false,
  [FILTER.MARKED]: false,
  [FILTER.REQUESTED]: false,
  [FILTER.ACCELERATION]: false,
  [FILTER.BRAKING]: false,
  [FILTER.CORNERING]: false,
  [FILTER.DISTRACTION]: false,
  [FILTER.TAILGATING]: false,
  [FILTER.COLLISION]: false,
  [FILTER.SMOKING]: false,
  [FILTER.CELL_PHONE]: false,
  [FILTER.REPEATED_EVENTS]: false,
  [FILTER.SAFETY_INCIDENT]: false,
};
