import React, { FC } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import i18n from './utils/i18n';
import Routes from './routes';
import { storeConfig } from './store-provider';
import GlobalContextWrapper from './components/context/global-context-wrapper';
import { ToastProvider } from 'react-toast-notifications';
import Snackbar from 'components/snackbar';
import RQWithDataProviders from './data-providers';

export const TOAST_COMPONENT = { Toast: Snackbar };

const FleetApp: FC = () => (
  <React.Suspense fallback={<div />}>
    <Provider store={storeConfig()}>
      <I18nextProvider i18n={i18n}>
        <GlobalContextWrapper>
          <BrowserRouter>
            <RQWithDataProviders>
              <ToastProvider
                components={TOAST_COMPONENT}
                autoDismiss={true}
                placement="bottom-center"
              >
                <React.Fragment>
                  <Route component={Routes} />
                </React.Fragment>
              </ToastProvider>
            </RQWithDataProviders>
          </BrowserRouter>
        </GlobalContextWrapper>
      </I18nextProvider>
    </Provider>
  </React.Suspense>
);

export default FleetApp;
