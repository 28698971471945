export const DRIVER_QUERIES = {
  DRIVER: 'driver',
  DRIVERS: 'drivers',
  INFINITE: 'infinite-drivers',
};

export const VEHICLE_QUERIES = {
  VEHICLE: 'vehicle',
  VEHICLES: 'vehicles',
  INFINITE: 'infinite-vehicles',
};

export const ACTIVITY_QUERIES = {
  DRIVERS: 'driver-activities',
  VEHICLES: 'vehicle-activities',
  INFINITE_DRIVERS: 'infinite-driver-activities',
  INFINITE_VEHICLES: 'infinite-vehicle-activities',
};

export const FACE_QUERIES = {
  PAGINATED_FACES: 'paginated-faces',
};
