import { createStore, Reducer, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {
  State,
  FilterType,
  RuleProperty,
  ModalID,
  AlertNotification,
  FormValues,
  IDType,
  defaultRules,
  Action,
  ActionCreator,
  Constant,
  ThunkResult,
  APIResult,
  ID,
} from './types';
import API from './api-requests';
import { alertTypeIDMap, AlertType } from 'constants/alert-types';
import { Driver, Vehicle } from '../../models/db';

const rulesMap = {
  [AlertType.Braking]: [RuleProperty.MinGForce],
  [AlertType.Distraction]: [RuleProperty.MinDuration],
  [AlertType.Tailgating]: [RuleProperty.MinDuration],
};
const idMap = {
  [FilterType.Drivers]: IDType.Drivers,
  [FilterType.Vehicles]: IDType.Vehicles,
  [FilterType.Subfleets]: IDType.Subfleets,
};

export const setAlertList: ActionCreator = (alerts: AlertNotification[]) => ({
  type: Constant.GET_ALERTS,
  payload: {
    alertList: alerts,
  },
});

export const setAlertType: ActionCreator = (alertType: AlertType) => ({
  type: Constant.SET_ALERT_TYPE,
  payload: {
    alertType,
  },
});

export const filterBy: ActionCreator = (filter: FilterType) => ({
  type: Constant.FILTER_BY,
  payload: {
    filter,
  },
});

export const searchBy: ActionCreator = (search: string) => ({
  type: Constant.SEARCH_BY,
  payload: {
    search,
  },
});

interface Selection {
  selected: ID[];
  filter: FilterType;
  entities: any[];
}
export const selectEntities: ActionCreator = ({
  selected,
  filter,
  entities,
}: Selection) => {
  selected = cleanSelection(filter, selected, entities);
  return {
    type: Constant.SELECT_ENTITIES,
    payload: {
      selected,
    },
  };
};

export const hideModals: ActionCreator = () => ({
  type: Constant.HIDE_MODALS,
});

export const showModal: ActionCreator = (id: ModalID) => ({
  type: Constant.SHOW_MODAL,
  payload: {
    modal: id,
  },
});

export const editAlert: ActionCreator = (alert: AlertNotification) => ({
  type: Constant.EDIT_ALERT,
  payload: {
    editing: alert,
  },
});

export const fetchAlerts = (): ThunkResult<APIResult> => dispatch =>
  API.getAlerts().then(alerts => {
    dispatch(setAlertList(alerts));
    return alerts;
  });

export const postAlert = (
  values: FormValues,
  fleet: string,
): ThunkResult<APIResult> => (dispatch, getState) => {
  const { alertType, selected, filter, alertList, editing } = getState();

  const body = [
    {
      alert_type: alertTypeIDMap[alertType],
      email_opt: true,
      properties: {
        default_rule: defaultRules[alertType],
        rules: [
          ...alertList
            // remove all alerts of different alert types
            .filter(i => i.type === alertType)
            // if we're editing an alert, filter out the old version of it
            .filter(i => (!!editing && editing.id !== i.id) || !editing)
            .map(i => i.rule),
          {
            name: values.alertName,
            fleet_id: fleet,
            [idMap[filter]]: selected,
            filter,
            selected,
            enabled: values.enabled,
            ...rulesMap[alertType].reduce(
              (acc, r) => ({
                ...acc,
                [r]: values[alertType][r],
              }),
              {},
            ),
          },
        ],
      },
    },
  ];

  return API.postAlert({ body }).then(alerts => {
    dispatch(setAlertList(alerts));
    dispatch(clearEditing());
    return alerts;
  });
};

export const toggleAlert = (
  alert: AlertNotification,
): ThunkResult<APIResult> => (dispatch, getState) => {
  const { alertList } = getState();

  const body = [
    {
      alert_type: alertTypeIDMap[alert.type],
      email_opt: true,
      properties: {
        default_rule: defaultRules[alert.type],
        rules: [
          ...alertList
            .filter(i => i.type === alert.type)
            .filter(i => i.id !== alert.id)
            .map(i => i.rule),
          {
            ...alert.rule,
            enabled: !alert.rule.enabled,
          },
        ],
      },
    },
  ];

  return API.postAlert({ body }).then(alerts => {
    dispatch(setAlertList(alerts));
    return alerts;
  });
};

export const removeAlert = (
  alert: AlertNotification,
): ThunkResult<APIResult> => (dispatch, getState) => {
  const { alertList } = getState();
  const body = [
    {
      alert_type: alertTypeIDMap[alert.type],
      email_opt: true,
      properties: {
        default_rule: defaultRules[alert.type],
        rules: [
          ...alertList
            .filter(i => i.type === alert.type)
            .filter(i => i.id !== alert.id)
            .map(i => i.rule),
        ],
      },
    },
  ];

  return API.postAlert({ body }).then(alerts => {
    dispatch(setAlertList(alerts));
    return alerts;
  });
};

export const shouldShowModal = (id: ModalID) => store.getState().modal === id;

export const clearEditing: ActionCreator = () => ({
  type: Constant.CLEAR_EDITING,
});

export const initialState: State = {
  alertList: [],
  editing: null,
  filter: FilterType.Subfleets,
  search: '',
  selected: [],
  alertType: AlertType.Distraction,
  modal: null,
};

const reducer: Reducer<State> = (
  state = initialState,
  { payload, type }: Action,
) => {
  switch (type) {
    case Constant.DISABLE_ALERT:
    case Constant.REMOVE_ALERT:
    case Constant.GET_ALERTS:
      return {
        ...state,
        alertList: payload.alertList,
      };
    case Constant.SET_ALERT_TYPE:
      return {
        ...state,
        alertType: payload.alertType,
      };
    case Constant.EDIT_ALERT:
      return {
        ...state,
        editing: payload.editing,
        alertType: payload.editing.type,
        filter: payload.editing.rule.filter,
        modal: ModalID.Form,
      };
    case Constant.CLEAR_EDITING:
      return {
        ...state,
        editing: null,
        selected: [],
        alertType: initialState.alertType,
        filter: initialState.filter,
        search: initialState.search,
      };
    case Constant.FILTER_BY:
      return {
        ...state,
        filter: payload.filter,
        selected: [],
      };
    case Constant.SEARCH_BY:
      return {
        ...state,
        search: payload.search,
      };
    case Constant.SELECT_ENTITIES:
      return {
        ...state,
        selected: payload.selected,
      };
    case Constant.SHOW_MODAL:
      return {
        ...state,
        modal: payload.modal,
      };
    case Constant.HIDE_MODALS:
      return {
        ...state,
        modal: null,
      };
    default:
      return state;
  }
};

const store = createStore(reducer, applyMiddleware(thunk));

export { reducer };
export default store;

/**
 * NAUTO-39547: Clearing bad selected items from a selection
 * Clean when bug occurs when some driver and vehicles were saved together.
 * Also useful if a fleet/driver/vehicle is deleted from database
 */
function cleanSelection(filter: FilterType, ids: string[], entities: any[]) {
  switch (filter) {
    case FilterType.Subfleets:
      return cleanFleetIds(ids, entities);
    case FilterType.Drivers:
      return cleanDriverIds(ids, entities);
    case FilterType.Vehicles:
      return cleanVehiclesIds(ids, entities);
  }
  return ids;
}

function cleanDriverIds(ids: string[], drivers: Driver[]) {
  return ids.filter(id => drivers.some(d => d.id === id));
}

function cleanFleetIds(ids: string[], fleets: any[]) {
  const hasId = (f, id) =>
    f.id === id || (f.groups && f.groups.some(child => hasId(child, id)));

  return ids.filter(id => fleets.some(f => hasId(f, id)));
}

function cleanVehiclesIds(ids: string[], vehicles: Vehicle[]) {
  return ids.filter(id => vehicles.some(v => v.id === id));
}
