import { Vehicle } from 'models/db';
import { ReportRowData } from 'components/reports/types';

export interface FilterDataMap {
  [key: string]: {
    data: any;
    sort: Column | string;
    ascending: boolean;
  };
}

export enum Filter {
  FleetHealth = 'fleet_health',
  Loose = 'device_loose',
  Silent = 'device_silent',
  Obstructed = 'device_obstructed',
  RMAStatus = 'device_in_rma',
}

export enum Column {
  Icon = 'icon',
  Name = 'name',
  Loose = 'device_loose',
  Silent = 'device_silent',
  Obstructed = 'device_obstructed',
  ServiceStatus = 'service_status',
  RMAStatus = 'device_in_rma',
  FleetDetails = 'details_link',
  LicensePlate = 'license_plate',
  Vin = 'vin',
  State = 'state',
  Faults = 'faults',
  Odometer = 'odometer',
  EngineHours = 'engine_hours',
  IdleTime = 'idle_time',
  DeviceId = 'device_id',
  DeviceStatus = 'device_status',
  DaysOffline = 'days_offline',
  LastConnected = 'last_connected',
}

export enum CellAlign {
  left = 'left',
  center = 'center',
  right = 'right',
}

export const FilterToColumnMap = {
  [Filter.Loose]: Column.Loose,
  [Filter.Silent]: Column.Silent,
  [Filter.Obstructed]: Column.Obstructed,
  [Filter.FleetHealth]: Column.ServiceStatus,
  [Filter.RMAStatus]: Column.Name,
};
