import React, { FC } from 'react';
import { UserDataProvider } from 'hooks/use-user-data';
import { FeatureFlagsProvider } from 'hooks/use-feature-flags';
import { FleetDataProvider } from 'hooks/use-fleet-data';
import { GroupDataProvider } from 'hooks/use-group-data';
import { FleetFeaturesProvider } from 'hooks/use-fleet-features';
import { DriverDataProvider } from 'hooks/use-driver-data';
import { VehicleDataProvider } from 'hooks/use-vehicle-data';
import { VeraScoreTypeDataProvider } from 'hooks/use-vera-score-type-toggle';
import { UserPermissionProvider } from 'hooks/use-permissions';
import { DeviceProvider } from 'components/map/context';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LoggedInUserTypeProvider } from 'hooks/use-logged-in-user-type';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      refetchIntervalInBackground: false,
    },
  },
});

export const DataProviders: FC = ({ children }) => (
  <UserDataProvider>
    <FeatureFlagsProvider>
      <LoggedInUserTypeProvider>
        <VeraScoreTypeDataProvider>
          <FleetDataProvider>
            <FleetFeaturesProvider>
              <DriverDataProvider>
                <VehicleDataProvider>
                  <GroupDataProvider>
                    <UserPermissionProvider>
                      <DeviceProvider>{children}</DeviceProvider>
                    </UserPermissionProvider>
                  </GroupDataProvider>
                </VehicleDataProvider>
              </DriverDataProvider>
            </FleetFeaturesProvider>
          </FleetDataProvider>
        </VeraScoreTypeDataProvider>
      </LoggedInUserTypeProvider>
    </FeatureFlagsProvider>
  </UserDataProvider>
);

const WithQueryProvider: FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <DataProviders>{children}</DataProviders>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default WithQueryProvider;
