import React from 'react';
import styled from 'styled-components';
import connect from 'utils/connect';
import { createStructuredSelector } from 'reselect';
import { toggleInfoModal } from './notifications.redux';
import { colors } from '@nauto/uikit/dist/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import InfoModal from 'atoms/modal-info';
import IconPanel from 'atoms/icon-panel';
import IconLink from 'atoms/icon-link';
import { linkKeys } from 'utils/i18n-link-keys';
import { spaceSizesPx, Text, colors as coreColors, Box } from '@nauto/core';
import { compose } from 'recompose';
import withFeatureFlags, {
  FeatureFlagsProps,
} from 'decorators/with-feature-flags';

interface Props extends WithTranslation, FeatureFlagsProps {
  dispatch?: any;
  isInfoModalOpened?: boolean;
}

const selector = createStructuredSelector({
  isInfoModalOpened: ({ notificationsData }) =>
    notificationsData.isInfoModalOpened,
});

class NotificationsInfoModal extends React.Component<Props> {
  taggingSupportUrl = this.props.featureFlags.sfHelpCenterPages
    ? this.props.t(linkKeys.taggingHelp)
    : this.props.t(linkKeys.taggingHelpZendesk);

  componentDidUpdate(prevProps: any) {
    if (prevProps.isInfoModalOpened !== this.props.isInfoModalOpened) {
      this.props.isInfoModalOpened
        ? (document.body.style.overflowY = 'hidden')
        : (document.body.style.overflowY = 'auto');
    }
  }

  // tslint:disable:quotemark
  getModalContent = () => (
    <IconPanel
      title={this.props.t('Driver tagging')}
      icon="user"
      iconColor={colors.success}
      iconSize="lg"
    >
      <div>
        <div>
          {this.props.t(
            "It’s important to tag your drivers regularly so that our system understands who they are. 'Tagging' means that you’re matching a new face with a driver name and profile. To avoid creating duplicates of the same driver, please first check your existing driver list before adding a new driver.",
          )}
        </div>
        {!!this.taggingSupportUrl && (
          <Box marginTop="lg" justify="center">
            <IconLink
              icon="playLabel"
              color={colors.success}
              link={this.taggingSupportUrl}
            >
              <Text weight="Bold" color={coreColors.brand}>
                {this.props.t('How to set up new drivers and tag them')}
              </Text>
            </IconLink>
          </Box>
        )}
      </div>
    </IconPanel>
  );

  closeInfoModal = () => {
    const { dispatch } = this.props;
    dispatch(toggleInfoModal(false));
  };

  render() {
    const { isInfoModalOpened } = this.props;

    return (
      isInfoModalOpened && (
        <InfoModal
          id="notification-info-modal"
          content={this.getModalContent()}
          onClose={this.closeInfoModal}
        />
      )
    );
  }
}

const StyledComponent = styled(NotificationsInfoModal)`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spaceSizesPx.xl} ${spaceSizesPx.xxl} ${spaceSizesPx.xxl};

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${spaceSizesPx.lg};
    color: ${colors.success};
    font-family: Circular-Bold;
    .icon {
      margin-right: ${spaceSizesPx.sm};
    }
    :hover {
      color: ${colors.green300};
    }
  }
`;

export default compose(
  withFeatureFlags,
  connect(selector),
  withTranslation(),
)(StyledComponent);
