/**
 * the sum of the values in an array
 */
export const sum = (values: number[]) => values.reduce((a, b) => a + b, 0);

/**
 * weightedAverage
 */
export const weightedAvg = (data: Array<{ val: number; weight: number }>) => {
  let total = 0;
  let totalWeight = 0;
  data.forEach(d => {
    total += d.val * d.weight;
    totalWeight += d.weight;
  });
  return total / totalWeight;
};

/**
 * the average of an array of numbers
 */
export const mean = (values: number[]) => sum(values) / values.length;

/**
 * the standard deviation of an array of numbers
 * @param values array of numbers to get std of
 * @param preCalculatedAvg if we already know the mean/avg, we can provide that value to save an unnecesary iteration through the elements
 */
export const standardDeviation = (
  values: number[],
  preCalculatedAvg?: number,
) => {
  const avg: number = preCalculatedAvg || mean(values);
  const squareDiffs: number[] = values.map((val: number): number => {
    const diff = val - avg;
    return diff * diff;
  });
  const avgSquareDiff: number = mean(squareDiffs);
  return Math.sqrt(avgSquareDiff);
};

export const wholeNumberMean = (data: number[]): number =>
  Math.round(mean(data));

export const round = (data: number, digits: number): number =>
  Math.round(data * Math.pow(10, digits)) / Math.pow(10, digits);

export const percentage = (value: number, total: number): number => {
  if (isNaN(value) || isNaN(total)) {
    return undefined;
  }
  return Math.round((value / total) * 100 || 0);
};
