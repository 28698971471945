import * as React from 'react';
import { render } from 'react-dom';
import 'core-js';
import reportWebVitals from 'report-web-vitals';
import * as API from '@nauto/api';
import FleetApp from './app';
import banner from './utils/banner';
import { storeConfig } from './store-provider';
import 'url-polyfill';
import 'utils/moment-english-locale';
import './event-polyfill';
import { getLocale } from 'utils/localstorage';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import packageJson from '../package.json';
import 'regenerator-runtime/runtime';

declare let window: any;
declare let mixpanel: any;

/**
 * Instantiate Redux Devtools Extension integration
 */
/* tslint:disable:no-namespace */
declare global {
  /* tslint:disable:interface-name */
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
    Cypress: any;
    store: any;
  }
}

/**
 * Instantiate hot reloading
 */
declare let module: any;
if (module.hot) {
  module.hot.accept();
}

/**
 * Instantiate the default options for Nauto API layer
 */
API.setOptions({ token: null, locale: getLocale(true) });

/**
 * Exposing store for Cypress Tests
 */
if (window.Cypress) {
  window.store = storeConfig();
}

const history = createBrowserHistory();
if (process.env.NODE_ENV === 'production') {
  const SENTRY_RELEASE = Math.round(new Date().getTime() / 1000).toString();
  Sentry.init({
    dsn:
      'https://afe4542db4394d3bb4abc61f949be3f4@o92628.ingest.sentry.io/212961',

    release: SENTRY_RELEASE,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

/**
 * Intantiate Fleet App
 */
console.log(banner());

if (process.env.COMMIT_SHA) {
  console.log(`SHA: ${process.env.COMMIT_SHA}`);
  console.log(`VERSION: ${packageJson.version}`);
}

if (process.env.ENV) {
  console.log(`env: ${process.env.ENV}`);
}

/**
 * Mixpanel web-vitals tracking
 */

const sendWebVitalsToMixpanel = metric => {
  if (window.mixpanel) {
    mixpanel.track(metric.name, {
      ...metric,
      pathname: window.location.pathname,
    });
  }
};

render(<FleetApp />, document.getElementById('app'));

reportWebVitals(sendWebVitalsToMixpanel);

export { API };
