import { FEATURES } from 'components/feature/constants';
import { Permissions } from './rules';

export const DEFAULT_FLEET_PROPERTIES = {
  out_camera_media_disabled: false,
  in_camera_media_disabled: false,
};

export const USER_MANAGEMENT_VERSION = {
  ONE: '1',
  TWO: '2',
};

// Need a better way to store this role set as it is not scalable
export const GLOBAL_ADMIN_ROLES = {
  driver_insights: 'true',
  'driver_insights-insights': 'full-access',
  'driver_insights-fleet': 'full-access',
  'driver_insights-events': 'full-access',
  'driver_insights-maps': 'full-access',
  vehicle_insights: 'true',
  'vehicle_insights-insights': 'full-access',
  'vehicle_insights-fleet': 'full-access',
  'vehicle_insights-events': 'full-access',
  'vehicle_insights-maps': 'full-access',
  reports: 'true',
  'reports-collision': 'full-access',
  'reports-at_risk': 'full-access',
  'reports-device_health': 'full-access',
  'reports-real_time_alert_effectiveness': 'full-access',
  'reports-policy_violations': 'full-access',
  'reports-coaching_effectiveness': 'full-access',
  'reports-top_performing': 'full-access',
  'reports-custom': 'full-access',
  coaching: 'full-access',
  tag_driver: 'full-access',
  video_request: 'full-access',
  user_management: 'full-access',
  role_management: 'full-access',
  fleet_settings: 'full-access',
  developer: 'full-access',
  config: 'full-access',
};

export const USER_MANAGEMENT_FEATURES = {
  [FEATURES.DRIVER_INSIGHTS]: [Permissions.DriverInsightsHome],
  [FEATURES.VEHICLE_INSIGHTS]: [Permissions.VehicleInsightsHome],
  [FEATURES.EVENTS]: [Permissions.DriverEvents, Permissions.VehicleEvents],
  [FEATURES.REPORTS]: [
    Permissions.ReportsCoachingEffectiveness,
    Permissions.ReportsCollision,
    Permissions.ReportsCustomReports,
    Permissions.ReportsTopPerforming,
    Permissions.ReportsAtRisk,
    Permissions.ReportsDeviceHealth,
    Permissions.ReportsRealTimeEffectiveness,
    Permissions.ReportsPolicyViolations,
  ],
  [FEATURES.VEHICLE_MANAGEMENT]: [
    Permissions.VehicleManagement,
    Permissions.DriverManagement,
  ],
  [FEATURES.DRIVER_MANAGEMENT]: [
    Permissions.DriverManagement,
    Permissions.VehicleManagement,
  ],
  [FEATURES.DRIVER_COACHING]: [Permissions.CustomCoaching],
  [FEATURES.TRIPS]: [Permissions.DriverMaps, Permissions.VehicleMaps],
};
