import * as API from '@nauto/api';
import { useQueries } from 'react-query';

// QueryResult is not on this react-query version

type DriversQueryPayload = {
  data: API.Driver[];
  isLoading: boolean;
  isError: boolean;
};

const useDriversQuery = ({
  ids,
  fleetId,
  groupId,
}: {
  ids: string[];
  fleetId: string;
  groupId: string;
}): DriversQueryPayload => {
  const getDriversById = async id => {
    const res = await API.drivers.getDriversById({
      ids: id,
      fleetId,
      groupId,
    });
    return res;
  };

  const drivers = useQueries(
    ids.map(id => {
      return {
        queryKey: ['driver', id],
        queryFn: () => getDriversById(id),
      };
    }),
  );

  const data = drivers.map(d => d.data);
  const isLoading = drivers.some(d => d.isLoading);
  const isError = drivers.some(d => d.isError);

  return {
    data,
    isLoading,
    isError,
  };
};

export default useDriversQuery;
